import Helpers from "commons/helpers";
import Constants from "constants/index";
import IdentityService from "./indentity.service";

class ProfileService extends IdentityService {
    public getUserInfo = async () => {
        const result = await this.api.get({
            path: Constants.ApiPath.PROFILE.USER_INFO,
        });
        return result.data.result;
    };

    public getUserAuthorization = async (organizationId?: string) => {
        const result = await this.api.get({
            path: Constants.ApiPath.PROFILE.USER_AUTHORIZATION,
            query: Helpers.isNullOrEmpty(organizationId) ? undefined : { organizationId: organizationId },
        });
        return result.data.result;
    };

    public getUserSummary = async () => {
        const result = await this.api.get({
            path: Constants.ApiPath.PROFILE.USER_SUMMARY,
        });
        return result.data;
    };
}

export default ProfileService;
