import Constants from "constants/index";
import IdentityService from "./indentity.service";
import { ITenant, ITenantClient } from "commons/interfaces";

export type IRequestUpdateTenant = {
    name: string;
    tenantCode: string;
    description: string;
    status: number;
    logoId: string;
    logoUrl: string;
    owner: string;
    serviceCodes: string[];
    id: string;
    updateTime: string;
};

type IServiceClient = {
    id: string;
    clientId: string;
    clientType: number;
    defaultClient: number;
    accessRoleGroup: number;
    status: number;
    serviceCode: string;
};

export type IRequestUpdateTenantServiceClient = {
    tenantCode: string;
    serviceClients: IServiceClient[];
};

export type IRequestCreateTenant = Omit<IRequestUpdateTenant, "id" | "update">;

class TenantService extends IdentityService {
    public getAll = async (data?: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.TENANT.GET_ALL}?${data ? data : ""}`,
        });
        return result.data.result;
    };

    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.TENANT.GET_PAGED}?${data}`,
        });
        return result.data.result;
    };

    public getDetail = async (id: string): Promise<ITenant> => {
        const result = await this.api.get({
            path: Constants.ApiPath.TENANT.GET_DETAIL + `/${id}`,
        });
        return result.data.result;
    };

    public create = async (data: IRequestCreateTenant) => {
        const result = await this.api.post({
            path: Constants.ApiPath.TENANT.CREATE,
            data: data,
        });
        return result.data;
    };

    public update = async (data: IRequestUpdateTenant) => {
        const result = await this.api.post({
            path: Constants.ApiPath.TENANT.UPDATE,
            data: data,
        });
        return result.data;
    };
    public updateTenantServiceClient = async (data: IRequestUpdateTenantServiceClient) => {
        const result = await this.api.post({
            path: Constants.ApiPath.TENANT.UPDATE_TENANT_SERVICE_CLIENT,
            data: data,
        });
        return result.data;
    };

    public delete = async (id: string) => {
        const result = await this.api.delete({
            path: Constants.ApiPath.TENANT.DELETE + `/${id}`,
        });
        return result.data;
    };
}

export default TenantService;
