import { Add } from '@mui/icons-material';
import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Strings from "constants/strings";
import Constants from '../../constants';
import ClientService from 'services/identity/client.service';

import { RootState } from 'store';
import { DashboardLayout } from "layout";
import { Mode, Status } from 'constants/enum';
import { ICodename } from 'commons/interfaces';
import { setDataAlert } from 'store/slice/message.slice';
import { showLoading } from "store/slice/loadingAPI.slice";
import { setListPathName } from 'store/slice/titleRoute.slice';
import { Avatar, Box, Button, DataTable, Typography, useCommonComponentContext } from "@maysoft/common-component-react";

interface RequestData {
    searchText?: string;
    pageSize?: number;
    pageNumber?: number;
    totalCount?: number;
}

interface IModel {
    requestData?: RequestData;
    requestDataTemp?: RequestData;
}

const clientService = new ClientService();

export const commonGetAllDataClient = async (params?: { serviceCode?: string }) => {
    try {
        const listClient: ICodename[] = [];

        const resultClient = await clientService.getAll(params);
        [...resultClient || []].forEach((item: any) => {
            listClient.push({ code: item.clientId, name: item.clientName?.trim() || item.clientId });
        });

        return listClient;
    } catch (error) { return []; }
}

const ClientScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const [dataTable, setDataTable] = useState<any[]>([]);
    const [model, setModel] = useState<IModel>({} as IModel);

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);
    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.MANAGE_CLIENT);

    useEffect(() => {
        const pageNumber = Number(searchParams.get("pageNumber") || 1);
        const pageSize = Number(searchParams.get("pageSize") || Constants.ROW_PER_PAGE);
        const item = listPathName.find(el => el.pathName === Screens.CLIENT_LIST);

        getPaged({ pageNumber, pageSize, totalCount: item?.totalCount });
    }, [])

    const getPaged = async (data?: RequestData) => {
        try {
            dispatch(showLoading(true));
            const pageSize = data?.pageSize || Constants.ROW_PER_PAGE;
            const pageNumber = Helpers.getPageNumber(
                data?.pageNumber || 1,
                pageSize,
                data?.totalCount || 0,
            );

            const requestParam: any = {
                pageSize,
                pageNumber,
                searchText: data?.searchText,
            }

            const dataRows: any[] = [];
            const result = await clientService.getPaged(requestParam);
            (result.items || []).forEach((item: any) => {
                dataRows.push({
                    ...item,
                    status: (item.enabled === true) ? Status.Active : Status.Inactive,
                });
            });

            setDataTable(dataRows);
            setModel({
                requestData: {
                    ...requestParam,
                    totalCount: result.totalCount,
                },
                requestDataTemp: {
                    ...requestParam,
                    totalCount: result.totalCount,
                }
            });

            let query = `?pageNumber=${pageNumber}&pageSize=${requestParam.pageSize}`;

            navigate(Screens.CLIENT_LIST + query, { replace: true });

            dispatch(setListPathName({ pathname: Screens.CLIENT_LIST, query, totalCount: result.totalCount }));
            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    }

    const onChangeValue = (key: string, val: any, isTemp: boolean) => {
        let data: any = { ...model.requestDataTemp };
        data[key] = val;
        if (isTemp) {
            setModel({ ...model, requestDataTemp: data })
        } else {
            setModel({ ...model, requestData: data })
        }
    }

    const handleDelete = (id: string) => {
        Helpers.showConfirmAlert(Strings.Message.CONFIRM_DELETE, async () => {
            try {
                dispatch(showLoading(true));
                const result = await clientService.delete(id);
                if (result.statusCode === Constants.ApiCode.SUCCESS) {
                    dispatch(setDataAlert({ message: Strings.Message.DELETE_SUCCESS, type: "success" }));
                    await getPaged(model.requestData);
                }
                dispatch(showLoading(false));
            } catch (error: any) {
                dispatch(showLoading(false));
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            }
        })
    }

    return (
        <DashboardLayout
            isPermission={resourcePermissions.canRead}
            title={Strings.CLIENT.TITLE_LIST_VIEW}
            route={[{ title: Strings.CLIENT.TITLE_MENU, route: "" }]}
        >
            <Grid container spacing={2}>
                {
                    resourcePermissions.canCreate &&
                    <Grid item xs={12}>
                        <Box justifyContent="flex-end" display="flex">
                            <Button onClick={() => navigate(Screens.CLIENT_CREATE)}>
                                <Add />&nbsp;
                                {Strings.Common.ADD_NEW}
                            </Button>
                        </Box>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Card>
                        <Box p={2}>
                            <DataTable
                                loading={false}
                                rowPerPage={model.requestData?.pageSize}
                                totalCount={model.requestData?.totalCount}
                                pageNumber={model.requestData?.pageNumber}
                                onChangePageSize={(pageSize) => getPaged({ ...model.requestData, pageSize })}
                                onChangePageNumber={(pageNumber) => getPaged({ ...model.requestData, pageNumber })}

                                placeholderSearch={Strings.Common.SEARCH_TEXT}
                                searchText={model.requestData?.searchText || ""}
                                onSearchChange={(val) => { onChangeValue("searchText", val, false) }}
                                onSearch={(val) => { getPaged({ ...model.requestData, searchText: val }) }}

                                table={{
                                    columns: [
                                        {
                                            Header: Strings.CLIENT.NAME, accessor: "clientId", width: "210px",
                                            Cell: (row) => (
                                                <Box display="flex" alignItems="center">
                                                    <Avatar
                                                        alt="avt-staff"
                                                        text={row.value || ""}
                                                        src={row.row?.original?.logoUri}
                                                        sx={{
                                                            width: "36px",
                                                            height: "36px",
                                                            objectFit: "cover",
                                                            marginRight: "8px",
                                                            borderRadius: "50%",
                                                        }}
                                                    />
                                                    <Box display="grid">
                                                        <Typography
                                                            color="text"
                                                            variant="caption"
                                                            fontWeight="medium"
                                                        >
                                                            {row.row?.original?.clientName || row?.value || ""}
                                                        </Typography>
                                                        <Typography
                                                            color="secondary"
                                                            variant="caption"
                                                        >
                                                            ID: {row?.value || ""}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )
                                        },
                                        { Header: Strings.CLIENT.ACCESS_TOKEN_LITE_TIME, accessor: "accessTokenLifetime", width: "80px" },
                                        { Header: Strings.CLIENT.ABSOLUTE_REFRESH_TOKEN_LIFE_TIME, accessor: "absoluteRefreshTokenLifetime", width: "120px" },
                                        { Header: Strings.CLIENT.SLIDING_REFRESH_TOKEN_LIFE_TIME, accessor: "slidingRefreshTokenLifetime", width: "120px" },
                                        { Header: Strings.CLIENT.CLIENT_URI, accessor: "clientUri", width: "170px" },
                                    ],
                                    rows: dataTable
                                }}

                                actionList={(row) => [
                                    {
                                        key: "detail",
                                        iconName: "view",
                                        title: Strings.Common.VIEW_ALL,
                                        callback: (row) => navigate(Screens.CLIENT_EDIT + `?id=${row.id}&mode=${Mode.View}`),
                                    },

                                    resourcePermissions.canUpdate
                                    && (row.enabled === true) &&
                                    {
                                        key: "edit",
                                        iconName: "edit",
                                        title: Strings.Common.EDIT,
                                        callback: (row) => navigate(Screens.CLIENT_EDIT + `?id=${row.id}&mode=${Mode.Update}`),
                                    },

                                    resourcePermissions.canDelete
                                    && (row.enabled === true)
                                    && {
                                        key: "delete",
                                        iconName: "delete",
                                        title: Strings.Common.DELETE,
                                        callback: (row) => handleDelete(row.id),
                                    },
                                ]}
                            />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}

export default ClientScreen;