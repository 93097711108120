// PUBLIC
export enum DataPermission {
    False = 0,
    True = 1,
}

export enum Status {
    Deleted = -9,
    PendingInvitation = -3,
    Blocked = -2,
    Draft = -1,
    Inactive = 0,
    Active = 1,
    Accept = 2,
    Decline = 3,
}

export enum Gender {
    Male = 0,
    Female = 1,
    Other = 2,
}

export enum IsTrue {
    False = 0,
    True = 1,
}

export enum Mode {
    Create = 0,
    Update = 1,
    View = 2,
}

export enum ActionRequest {
    Create = 0,
    Update = 1,
    Delete = -1,
    Empty = -2,
    Detail = -33,
}

export enum ActionTypeSelectBox {
    Delete = -9,
    Submit = 9,
    Cancel = 99,
}

export enum MultipleApply {
    False = 0,
    True = 1,
}

export enum BranchType {
    CompanyHeadOffice = 0,
    Branch = 1,
    Store = 2,
}

export enum UserType {
    Admin = 1,
    User = 2,
}

export enum ModuleType {
    Web = 0,
    App = 1,
}

export enum AccessMode {
    Public = 0,
    Private = 1,
    Internal = 2,
    PrivateRBAC = 4,
}

export enum PermissionType {
    Read = 32768,
    ListOrExport = 16384,
    Create = 8192,
    BulkCreateOrImport = 4096,
    Update = 2048,
    BulkUpdate = 1024,
    Delete = 512,
    BulkDelete = 256,
    None = 0,
}

export enum GroupType {
    Company = 0,
    Department = 1,
    BranchStore = 2,
    Membership = 64,
    Other = 127,
}

export enum FileAccessMode {
    Public = 0,
    Authenticated = 1,
    Internal = 2,
    Private = 4,
}

export enum FileProcessingStatus {
    Unprocessed = 0,
    Processing = 1,
    Processed = 2,
}

export enum ResourceType {
    Controller = 0,
    Module = 1,
    Service = 2,
}

export enum CodeNameCommon {
    USER_TITLE = "CD",
}

export enum GrantType {
    AuthorizationCode = "authorization_code",
    ClientCredentials = "client_credentials",
    ResourceOwnerPassword = "password",
}

export enum Scopes {
    AuthenticationScheme = "IdentityServerAccessToken",
    IdentityServerApi = "IdentityServerApi",
    PolicyName = AuthenticationScheme,
    OpenId = "openid",
    Profile = "profile",
    Email = "email",
    Address = "address",
    Phone = "phone",
    OfflineAccess = "offline_access",
}

export enum GetPagedType {
    CodeName,
}

export enum ClientType {
    Web = 1,
    Mobile = 2,
    Admin = 4,
    Checkout = 8,
    //Login = 16
}

export enum DefaultClient {
    None = 0,
    Default = 1,
}

export enum GroupUserDefault {
    None = 1,
    Default = 2,
}

export enum AdminFeature {
    None = 0,
    AdminFeature = 1,
}

export enum MessageRequestType {
    Absence = 0,
    ReportWork = 1,
    Invitation = 2,
    ForgotPassWord = 3,
    ResetPass = 4,
    Other = 9,
}

export enum MessageType {
    PushNotification,
    SMS,
    Email,
}

export enum ReadStatus {
    UnRead,
    Read,
}

export enum InvitationDetailType {
    Resource,
    Role,
    Group,
}

export enum ContactDefault {
    None = 0,
    Default = 1,
}

export enum ServiceOrganizationAction {
    Create = 0,
    Update = 1,
    Delete = -1,
}

export enum PolicyRequirementCompareType {
    Equal,
    NotEqual,
    LessThan,
    GreaterThan,
}

export enum PolicyRequirementType {
    Role = 1,
    User = 2,
    Group = 4,
    OtherClaim = 8,
}

export enum SystemSettingType {
    MessageFireBase = "MF000000",
}

export enum EventType {
    Send = 0,
    Receive = 1,
}

export enum ProcessStatus {
    Pending = -1,
    Processing = 0,
    Success = 1,
    Failure = 2,
}

export enum AppType {
    Website = 1,
    AndroidApp = 2,
    IOSApp = 3,
}

export enum OrganizationType {
    Normal = 0,
    Main = 1,
}
