/* eslint-disable import/no-anonymous-default-export */
// =============================================================================
// Lang for Vietnamese
// ==================================

export default {
    App: {
        TITLE: "Identity",
    },

    Languages: {
        VIETNAM: "Viet Nam",
        VI: "Vietnamese",
        EN: "English",
    },

    Common: {
        WEB: "Web",
        NEXT: "Next",
        PREVIEW: "Preview",
        APP_MOBILE: "App Mobile",
        SELECT: "Select",
        COMMON: "Common",
        CONTINUE: "Continue",
        PAUSE: "Pause",
        CANCEL: "Cancel",
        CANCELED: "Canceled",
        CANCEL_SELECT: "Unchecked",
        DISPLAY: "Display",
        TOTAL: "Total",
        NOTIFICATION: "Notification",
        OK: "OK",
        SAVE: "Save",
        SAVE_TEMP: "Save temp",
        COMPLETE: "Complete",
        WAIT_TEST: "Wait test",
        PROCESSING: "Processing",
        UPDATE: "Update",
        IN_ACTIVE: "In active",
        ACTIVE: "Active",
        REMOVE_FROM_SERVICE: "Remove from service",
        EMPTY_DATA: "Empty data",
        NO_DATA: "No data",
        ADD_NEW: "Add new",
        ADD_IMAGE: "Add image",
        GO_BACK: "Back",
        EXIT: "Exit",
        CREATE_NEW: "Create new",
        VIEW_ALL: "View detail",
        EDIT: "Edit",
        LOCK: "Lock",
        LOCKED: "Locked",
        DRAFT: "Draft",
        UNBLOCK: "Unblock",
        DELETE: "Delete",
        REPLACE: "Replace",
        UPLOAD: "Upload",
        UPLOAD_IMAGE: "Upload image",
        SEARCH: "Search",
        SEE: "See",
        ACTION: "Action",
        MILLION: "Million",
        BILLION: "Billion",
        UNCONFIRM: "Unconfirm",
        FILTER: "Filter",
        FILTER_LIST: "Filter list",
        RESET: "Reset",
        TYPE: "Type",
        PHONE_NUMBER: "Phone number",
        ENTER_PHONE_NUMBER: "Enter phone number",
        CODE: "Code",
        INPUT: "Input",
        INPUT_NOTE: "Enter note",
        EMAIL: "Email",
        TITLE: "Title",
        DAY: "Day",
        // TABLE
        LABEL_DISPLAY_ROWS: "{0} - {1} on {2}",
        DOWN: "Down load",
        DETAIL: "Detail",
        APPROVE: "Approve",
        REJECT: "Reject",
        REJECT_INVITATION: "Reject invitation",
        GENDER: "Gender",
        TIME: "Time",
        START_TIME: "Start time",
        END_TIME: "End time",
        DISABLE: "Disable",
        STATUS: "Status",
        COPY: "Copy",
        IMPORT: "Import",
        EXPORT: "Export",
        CITY: "City",
        PROVINCE: "Provice",
        SELECT_PROVINCE: "Select province",
        DISTRICT: "District",
        SELECT_DISTRICT: "Select district",
        WARDS: "Wards",
        SELECT_WARDS: "Select wards",
        SEARCH_TEXT: "Search key",
        ENTER_SEARCH_TEXT: "Enter search key",
        ORGANIZATION: "Organization",
        ACTION_WITH_EXCEL_FILE: "Action with excel",
        EXPORT_EXCEL: "Export excel",
        IMPORT_EXCEL: "Import excel",
        SELECT_FILE_FROM_DEVIDE: "Select file from device",
        INVALID_DATA: "Invalid data",
        FIELD_REQUIRED: "Field required",
        ACCEPT: "Accept",
        ATTENTION: "Attention",
        MALE: "Male",
        FEMALE: "Female",
        ALL: "All",
        CHANGE_STATUS: "Change status",
        CONFIRM: "Confirm",
        DATE_FORMAT: "vi",
        CLOSE: "Close",
        PRINT: "Print",
        EXPORT_PDF: "Export PDF",
        ADDRESS_LINE: "Address line",
        ADDRESS_LINE_PHD: "Enter address line",
        NAME: "First and last name",
        NOTE: "Note",
        BIRTHDATE: "Birthdate",
        APPLY: "Apply",
        NOT_APPLY: "Not apply",
        VIEW: "View detail",
        HERE: "Here",
        UPLOAD_FILE: "Upload file",
        LOAD_MORE: "Load more",
        USE_ALL_SYSTEM: "Use all system",
        USE: "Use",
        FAX_NUMBER: "Fax",
        TAX_NUMBER: "Tax number",
        YES: "Yes",
        NO: "No",
        SELECT_STATUS: "Select status",
        INVITE: "Invitation",
        SELECTED_RECORD: "Selected {0} records",
        UNREAD: "unread",
        MARK_READ_ALL: "Mark read all",
        ADDRESS_INFO: "Address information",
        PENDING_INVITATION: "Pending invitation",
        DELETED: "Deleted",
        COUNTRY_CODE: "Country code",
        SELECT_COUNTRY_CODE: "Select country code",
        LOADING: "Loading...",
        SKIP: "Skip",
        UNDER_MANAGEMENT: "Under management",
        ADVANCE: "Nâng cao",
        NORMAL: "Cơ bản",
        DEFAULT: "Default",
    },

    AlertStatus: {
        ERROR: "Error",
        WARNING: "Warning",
        INFO: "Info",
        SUCCESS: "Success",
        QUESTION: "Question",
    },

    Message: {
        ERROR: "An error occurred during processing, please try again later. Sorry for causing inconvenience.",
        SUCCESS: "Success",
        PLACEHOLDER: "Placeholder...",
        CONFIRM_LOGOUT: "Do you want to sign out?",
        CONFIRM_CREATE: "Do you want to create?",
        CONFIRM_UPDATE: "Do you want to update?",
        CONFIRM_DELETE: "Do you want to delete?",
        CONFIRM_DELETE_ALL: "Do you want to delete all records?",
        CONFIRM_BLOCK: "Do you want to block account?",
        CONFIRM_SUBMIT: "Do you want to submit?",
        CONFIRM_CANCEL: "Do you want to cancel?",
        CONFIRM_CANCEL_ACTIVE: "Do you want to cancel active?",
        CONFIRM_EXPORT_FILE: "Do you want to export file?",
        CONFIRM_APPROVE: "Do you want to apprive?",
        CONFIRM_REJECT: "Do you want to reject?",
        CREATE_SUCCESS: "Create success",
        UPDATE_SUCCESS: "Update success",
        PAYMENT_SUCCESS: "Payment success",
        UPDATE_STATUS_SUCCESS: "Update status success",
        EXPORT_FILE_SUCCESS: "Export file success",
        DELETE_SUCCESS: "Delete success",
        DELETE_ALL_SUCCESS: "Delete all records success",
        BLOCK_SUCCESS: "Block success",
        CANCEL_SUCCESS: "Cancel success",
        APPROVE_SUCCESS: "Approve success",
        REJECT_SUCCESS: "Reject success",
        LOGIN_SUCCESS: "Login success",
        LOGOUT_SUCCESS: "Logout success",
        CREATE_ERROR: "Create failed",
        BLOCK_ERROR: "Block failed",
        UPDATE_ERROR: "Update failed",
        EXPORT_FILE_ERROR: "Export file failed",
        CANCEL_ERROR: "Cancel failed",
        APPROVE_ERROR: "Approve failed",
        REJECT_ERROR: "Reject failed",
        DELETE_ERROR: "Delete failed",
        DELETE_ALL_ERROR: "Delete all records failed",
        LOGIN_ERROR: "Login failed",
        LOGOUT_ERROR: "Logout failed",
        EPR_TOKEN: "Login session has expired!",
        PASSWORD_ERROR: "User account or password incorrect",
        CREATE_USER_SUCCESS: "Create user success",
        PLEASE_COMPLETE_ALL_INFORMATION: "Please fill in all required information",
        INVALID_EMAIL: "Invalid email",
        INVALID_PHONENUMBER: "Invalid phone number",
        CONFIRM_CHANGE_STATUS: "Do you want to change status?",
        CHANGE_PHONE_NUMBER_SUCCESS: "Change phone number success",
        CHANGE_PASSWORD_SUCCESS: "Change password success",
        CHANGE_PROFILE_SUCCESS: "Change info profile success",
        NO_FUNCTION: "Sorry! This function is not available yet!",
        CANCEL_ACTION_MESSAGE: "Edit information has not been saved. Are you sure you want to leave the current screen?",
        TOKEN_EXPIRED: "Expired version login! Please log in again",
        USER_PROFILE_NOT_FOUND: "User information not found, please login again!",
        CONFIRM_EXPORT: "Do you want to file excel?",
        NOT_FORMAT_IMG: "The allowed image format is incorrect!(.png |.gif | .jpeg)",
        LIMIT_NUMBER_OF_PHOTO: "Limit adding a minimum of {0} images and a maximum of only {1} images",
        LIMIT_SIZE_OF_IMG: "File size larger than {0}MB",
        NOT_DATA_PERMISSION: "Rất tiếc, bạn không có quyền thao tác trên dữ liệu này",
        NOT_PERMISSION: "Sorry, you do not have permission to access. Please log in again!",
        NOT_FOUND: "Data cannot be found",
        NO_ROLE_UPDATE: "You do not have permission to access",
        NO_ROLE_DELETE: "you do not have permission to delete",
        GROUP_ALREADY_EXIST: "Group existed",
        PLEASE_ADD_PERMISSION: "Please add permissions to the group",
        PLEASE_ADD_GROUP_WORK: "Please add working group",
        DONT_HAVE_ORGANIZATION: "To use this function please create or ",
        CORRESPONDING_ORGANIZATION: " the corresponding organization.",
        CONFIRM_ACTIVE_STATUS: "You definitely want to switch to active status?",
        CONFIRM_IN_ACTIVE_STATUS: "You definitely want to switch to inactive status?",
        IN_ACTIVE_STATUS_SUCCESS: "Update status for successful decommissioning",
        ACTIVE_STATUS_SUCCESS: "Update status to successful operation",
        CHANGE_EMAIL_SUCCESS: "Change email success",
        NO_ADDRESS: "No information yet, please add address",
        EMAIL_ALREADY_EXISTS: "Email already exists",
        PLEASE_SELECT_AN_ORGANIZATION: "Please select an organization",
    },

    Validation: {
        EMAIL_ADDRESS: "Wrong email format",
        PHONE_NUMBER: "Wrong phone number format",
        DATE_TIME: "Wrong date time format",
        USERNAME: "Account name written without accents",
        REQUIRED: "Data field is required",
        REQUIRED_NAME: "Please enter your first and last name",
        REQUIRED_EMAIL: "Please enter your email",
        REQUIRED_USER_NAME: "Please enter your username",
        REQUIRED_PASSWORD: "Please enter a password",
        REQUIRED_CONFIRM_PASSWORD: "Please re-enter your password",
        REQUIRED_IMAGE: "Please choose a photo",
        REQUIRED_ORGANIZATION: "Please select a store",
        REQUIRED_ADDRESS: "Please add the address",
        NOT_SAME: "Password does not match",
        REQUIRED_NUMBER: "Please enter only numbers",
        LIMITED_SIZE: "The allowed length has been exceeded",
        REQUIRED_FORM: "Please double check your input",
        REQUIRED_AVATAR: "Please choose a profile picture",
        INVALID_TIME: "Invalid time",
        ID_CARD: "Wrong format of CMND/CCCD",
        REQUIRED_SPECIAL_CHARACTERS: "The data field does not contain special characters",
    },

    Login: {
        CONFIRM_PASSWORD: "Enter password",
        REGISTER_SUCCESSFULL: "Sign up success",
        REGISTER_ERROR: "Registration failed. Try again!",
        USER_NAME: "User name",
        PASSWORD: "Password",
        LOGOUT: "Logout",
        LOGIN: "Login",
        REGISTER: "Sign up",
        REMEMBER_ME: "Remember login",
        FORGOT_PASSWORD: "Forgot password",
        USER_INFO: "Profile",
        SELECT_ORGANIZATION: "Choose an organization",
        ORGANIZATION: "Organization",
        VERIFICATION_CODE: "Verification code",
        NEW_PASSWORD: "New password",
        CONFIRM_NEW_PASSWORD: "Confirm new password",
        NO_BELONG_TO_ANY_ORGANIZATION: "You are not currently part of an organization!, ask your admin to add it.",
    },

    SETTING_COMMON: {
        TITLE_MENU: "Setting Common",
    },

    SYSTEM_SETTING: {
        TITLE_MENU: "System setting",
        TITLE_LIST_VIEW: "List of system setting",
        TITLE_UPDATE_VIEW: "Update system setting information",
        TITLE_DETAIL_VIEW: "System setting information detail",

        CODE: "Code",
        NAME: "Name",
        VALUE: "Value",
        DESCRIPTION: "Desription",
        SETTING_CONFIG_FIREBASE: "Setting config firebase",
    },

    PROFILE: {
        TITLE_MENU: "Information profile",
        BASIC_INFO: "Basic information",
        PROFILE: "Profile",

        // Update email
        UPDATE_EMAIL: "Update email",
        UPDATE: "Update",
        NEW_EMAIL: "New email",
        ENTER_NEW_EMAIL: "Enter new email",
        GET_OTP_CODE: "Get OTP code",
        INPUT_OTP_CODE: "Enter OPT code",

        // OTPResendCountDownButton
        SEND_TO: "Send to",
        SEND_TO_AFTER: "Send to after",
        OTP_CODE: "OTP code",
        ENTER_OTP_CODE: "Enter otp code",

        // UpdatePasswordSection
        UPDATE_PASSWORD: "Update password",
        OLD_PASSWORD: "Old password",
        NEW_PASSWORD: "New password",
        CONFIRM_NEW_PASSWORD: "Confirm new password",
        ENTER_OLD_PASSWORD: "Enter old password",
        ENTER_NEW_PASSWORD: "Enter new password",
        ENTER_CONFIRM_NEW_PASSWORD: "Enter confirm new password",
        CONFIRM_NEW_PASSWORD_VALIDATE: "Enter confirmation password does not match",
        DEVICE_VERIFICATION: "Device Verification",
        CONFIRM_DEVICE_VERIFICATION: "We just sent a 4-character confirmation code to your {0} email. Please enter it in the box below",

        // User profile view
        FULL_NAME: "First and last name",
        ENTER_FULL_NAME: "Enter first and last name",
        EMAIL: "Email",
        ENTER_EMAIL: "Enter Email",
        PHONE_NUMBER: "Phone number",
        ENTER_PHONE_NUMBER: "Enter phone number",
        GENDER: "Gender",
        SELECT_GENDER: "Select gender",
        BIRTHDATE: "Birthdate",
        SELECT_BIRTHDATE: "Select birthdate",
        SELECT_EMPLOYMENT_DATE: "select employment date",
        EMPLOYMENT_DATE: "Employment date",
        TERMINATIONT_DATE: "Terminationt date",
        ENTER_TERMINATIONT_DATE: "Select terminationt date",
        IDENTITY_ID: "CMND/CCCD",
        IDENTITY_ID_ISSUED_DATE: "Date of issue of CMND/CCCD",
        IDENTITY_ID_ISSUED_PLACE: "Issued place",
        ENTER_IDENTITY_ID: "Enter CMND/CCCD",
        SELECT_IDENTITY_ID_ISSUED_DATE: "Select issued date",
        ENTER_IDENTITY_ID_ISSUED_PLACE: "Select issued place",
        SOCIAL_INSURANCE_CODE: "Social security code",
        ENTER_SOCIAL_INSURANCE_CODE: "Enter social security code",

        USER_INFORMATION: "User information",
        UPDATE_USER_INFORMATION: "Update user information",
        USER_AVATAR: "Avatar",
        UPLOAD_AVT_COMPLETE: "Photo update successful. Please wait a moment!",
        UPLOAD_AVT_ERROR: "An error occurred during processing. Photo upload failed!",

        USER_CONTACT: "User contact",
        DEFAULT_CONTACT: "Default",
    },

    DASHBOARD: {
        TITLE_MENU: "Dashboard",
        CREATE_ORGANIZATION: "New organization",
        TOTAL_MANAGEMENT_ORG: "Organization",
        TOTAL_MANAGEMENT_GROUP: "Group",
        TOTAL_MANAGEMENT_MEMBER: "Member",
        TOTAL_ORG_BELONG: "Total organizations belong",
        TOTAL_GROUP_BELONG: "Total groups belong",
        THAN_LAST_MONTH: "Than last month",
        MANAGEMENT: "Management",
        ORGANIZATION: "Organization",
        STATISTICAL: "Statistic",
    },

    ORGANIZATION: {
        TITLE_MENU_PARENT: "Organization",
        TITLE_MENU: "Organization",
        TITLE_LIST_VIEW: "Organization",
        TITLE_UPDATE_VIEW: "Update organization",
        TITLE_CREATE_VIEW: "New organization",
        TITLE_DETAIL_VIEW: "Organization",
        SEARCH_TEXT: "Search by name",
        WEBSITE: "Website",
        ENTER_WEBSITE: "Enter website",
        REGISTRATION_DATE: "Registration date",
        EXPIRATION_DATE: "Expiration date",
        SELECT_REGISTRATION_DATE: "Select registration date",
        SELECT_EXPIRATION_DATE: "Select expiration date",
        REGISTRATION_DATE_BIGGER_EXPIRATION_DATE: "Registration date must be less than expiration date",
        BIRTHDAY_BIGGER_CURRENT_DATE: "Date of birth must be less than current date",
        ADDRESS_LINE: "Address line",
        ENTER_ADDRESS_LINE: "Enter address line",
        DESCRIPTION: "Description",
        ENTER_DESCRIPTION: "Enter description",
        STORE_OWNER_INFO: "Owner information",
        STAFF_LIST: "Members",
        STORE_OWNER: "Owner",
        CHOOSE_OWNER: "Select owner",
        ADD_NEW_OWNER: "Add owner",
        ADDRESS_INFO: "Address",
        STORE_NAME: "Name",
        ENTER_STORE_NAME: "Enter name",
        OWNER_NAME: "Owner name",
        ENTER_OWNER_NAME: "Enter owner name",
        GROUP_NAME: "Working group name",
        GROUP: "Working group",
        SELECT_GROUP_NAME: "Select working group",
        ROLE_GROUP: "Group user",
        IMAGE_PHOTO: "Photo",
        GROUP_ADDRESS_LINE: "Address of group",
        ACTIVATED: "Activated",
        TENANT: "Tenant",
        ENTER_TENANT: "Select tenant",
        SERVICE: "Service",
        ENTER_SERVICE: "Select service",
        NO_ORGANIZATION: "Don't choose",
        STAFF_NAME: "Member",
        ACTION_ACTIVE: "Active",
        CONFIRM_REMOVE_SERVICE: "Do you really want to remove this organization from the service?",
        CONFIRM_DELETE_ALL_SERVICE: "Do you really want to remove this organization from all services?",
    },

    GROUP: {
        TITLE_MENU: "Group",
        TITLE_LIST_VIEW: "Group",
        TITLE_UPDATE_VIEW: "Update group",
        TITLE_CREATE_VIEW: "New group",
        TITLE_DETAIL_VIEW: "Group",
        BASIC_INFO: "Group",
        SEARCH_TEXT: "Search by code or name",
        INFO_STAFF_OF_GROUP: "Members",
        ORGANIZATION_PARENT: "Organization",
        GROUP_PARENT: "Parent group",
        SELECT_GROUP_PARENT: "Select parent group",
        NAME_GROUP: "Name",
        EMAIL_GROUP: "Email",
        ADDRESS_LINE: "Address",
        ENTER_NAME_GROUP: "Enter name",
        ENTER_EMAIL_GROUP: "Enter email",
        CODE_GROUP: "Code",
        SEARCH_PLACEHOLDER: "Enter code or name",
        ACTIVATED: "Activated",
        CHILD_NUMBER: "Number of child's group",
        CONFIRM_DELETE_WHEN_HAVE_CHILD_GROUP:
            "If you delete this group, the affiliated groups will be simultaneously deleted. Are you sure you want to delete?",
    },

    MODULE: {
        TITLE_MENU: "Module",
        TITLE_LIST_VIEW: "Module",
        TITLE_CREATE_VIEW: "New module",
        TITLE_UPDATE_VIEW: "Update modulen",
        TITLE_DETAIL_VIEW: "Module",
        BASIC_INFO: "Module",
        SEARCH_TEXT: "Search by code, name",

        ADD_NEW_MODULE: "Add new module",
        NAME: "Module name",
        INPUT_NAME: "Enter module name",
        CODE: "Module code",
        DESCIPTION: "Description",
        INPUT_DESCIPTION: "Enter description",
        NAME_RESOURCE: "Resource name",
        ADD_RESOURCE: "Add resource",
        SELECT_NAME_RESOURCE: "Select resource",
        IS_MENU: "Menu",
        PUBLIC: "Public",
        PRIVATE: "Need authentication",
        PRIVATERBAC: "Need permission",
        SELECT_MODULE: "Select module",
        ROLE_ACCESS: "Access role",
        ADMIN_FEATURE: "Admin feature",
    },

    ROLE: {
        TITLE_MENU: "Role",
        TITLE_LIST_VIEW: "Role",
        TITLE_CREATE_VIEW: "New Role",
        TITLE_UPDATE_VIEW: "Update Role",
        TITLE_DETAIL_VIEW: "Role",
        BASIC_INFO: "Role",
        SEARCH_TEXT: "Search by code, name",
        ENTER_SEARCH_TEXT: "Enter code, name",
        DISCRIPTION: "Description",
        CODE: "Code",
        NAME_ROLE: "Name",
        SHOW: "Show",
        RESOURCE: "Resource",
        ACTIVATED: "Activated",
        ROLE: "Role",
        READ: "Xem",
        VIEW_DETAIL: "Xem chi tiết",
        READ_LIST: "Xem danh sách",
        CREATE: "Tạo mới",
        BULK_CREATE: "Tạo nhiều",
        UPDATE: "Chỉnh sửa",
        BULK_UPDATE: "Chỉnh sửa nhiều",
        DELETE: "Xóa",
        BULK_DELETE: "Xóa nhiều",
        IMPORT: "Nhập dữ liệu",
        EXPORT: "Xuất dữ liệu",
        NONE: "Không",
        OWNER: "Owner",
        MANAGER: "Management",
        TYPE: "Type",
        SELECT_TYPE: "Select type",
        NORMAL: "Normal",
        DEFAULT_ROLE: "Default",
        MEMBER: "Member",
        OWNER_ROLE: "Organization Owner",
        ORGANIZATION_ADMIN: "Organization Admin",
        GROUP_ADMIN_ROLE: "Group admin",
        SERVICE_ADMIN: "Service Admin",
        WARNING_OWNER: "You do not have permission to update this role",
    },

    MENU: {
        TITLE_MENU: "Menu",
        TITLE_LIST_VIEW: "Menu",
        TITLE_CREATE_VIEW: "New menu",
        TITLE_UPDATE_VIEW: "Update menu",
        TITLE_DETAIL_VIEW: "Menu",
        BASIC_INFO: "Menu",
        SEARCH_TEXT: "Search by name",
        ENTER_SEARCH_TEXT: "Enter name",

        CODE: "Menu code",
        GROUP_MODULE: "Module",
        SELECT_GROUP_MODULE: "Select module",
        PARENT_MENU: "Parent menu",
        SELECT_PARENT_MENU: "Select parent menu",
        EXTERNAL_URL: "External url",
        ENTER_EXTERNAL_URL: "Enter external url",
        MENU_TITLE: "Title",
        ENTER_TITLE_MENU: "Enter title",
        ICON_NAME: "Icon",
        ENTER_ICON_NAME: "Select icon",
        DISPLAY_ORDER: "Display order",
        ENTER_DISPLAY_ORDER: "Enter display order",
        IS_EXTERNAL: "Add external url",
        ADD_ICON_IMG: "Logo",
        RESOURCE_URI: "Resource URI",
        ENTER_RESOURCE_URI: "Enter resource URI (<sevice>/<module>/<feature>)",
        EXTRA_INFORMATION: "Extra information",
        ENTER_EXTRA_INFORMATION: "Enter extra information",
        SCREEN_NAME: "Screen name",
        ENTER_SCREEN_NAME: "Enter screen name",
    },

    STAFF: {
        TITLE_MENU: "Member",
        TITLE_LIST_VIEW: "Member",
        TITLE_CREATE_VIEW: "New member",
        TITLE_UPDATE_VIEW: "Update member",
        TITLE_DETAIL_VIEW: "Member",
        TITLE_STAFF_WORKING_GROUP: "Working group",
        TITLE_STAFF_INFORMATION: "Member",

        CODE: "Code",
        FULL_NAME: "Member's first and last name",
        GENDER_MALE: "Male",
        GENDER_FEMALE: "Female",
        NAME: "First and last name",
        ENTER_NAME: "Enter first and last name",
        PHONE_NUMBER: "Phone number",
        ENTER_PHONE_NUMBER: "Enter phone number",
        EMAIL: "Email",
        ENTER_EMAIL: "Enter email",
        BIRTHDAY: "Birthday",
        SELECT_BIRTHDAY: "Select birthday",
        GENDER: "Gender",
        SELECT_GENDER: "Select gender",
        GROUP_USER_PERMISSION: "Member role",
        SELECT_GROUP_USER_PERMISSION: "Select member role",
        WORK_GROUPS: "Working group",
        SELECT_WORK_GROUPS: "Select working group",
        WORK_GROUPS_ALREADY_EXIST: "This working group already exists. please choose another group",
        NOTE: "Note",
        ENTER_NOTE: "Enter Note",
        ACTIVE: "Active",
        INACTIVE: "Iinactive",
        ALL: "All",
        MANAGER: "Group management",
        GROUP_DEFAULT: "Group default",
        USER_TITLE: "Title",
        SELECT_USER_TITLE: "Select title",
        REFER_TO_THE_LINK: "Refer to the link",
        ADD_STAFF: "Add member",
        ADD_WORK_GROUP: "Add working group",
        PLEASE_CHOOSE_GROUP_DEFAULT: "Please choose group default",
        SELECTED_GROUP_WILL_BE_DEFAULT_ONE: "Selected group will be the default group",

        MEMBER_INVITATION: {
            INVITATION: "Invite members",
            CREATE_INVITATION: "Create invitation",
            SEND_SUCCESS: "Send invitations success!",
        },
        UPDATE_USER_ROLE_SUCCESS: "Updated user role successfully",
        CONFIRM_DELETE_ALL_SERVICE: "Do you really want to remove this member from the services?",
        CONFIRM_DELETE: "Do you really want to delete this member?",
    },

    CLIENT: {
        TITLE_MENU: "Client",
        TITLE_LIST_VIEW: "Client",
        TITLE_CREATE_VIEW: "New client",
        TITLE_UPDATE_VIEW: "Update client",
        TITLE_DETAIL_VIEW: "Client",
        TITLE_INFOR: "Client",

        NAME: "Name",
        CLIENT_ID: "Client Id",
        ENTER_CLIENT_ID: "Enter Client Id",
        DESCRIPTION: "Description",
        CLIENT_URI: "URL",
        ALLOW_OFFLINE_ACCESS: "Allow offline access",
        ACCESS_TOKEN_LITE_TIME: "Access token life time",
        ABSOLUTE_REFRESH_TOKEN_LIFE_TIME: "Absolute refresh token life time (seconds)",
        ENTER_ABSOLUTE_REFRESH_TOKEN_LIFE_TIME: "Enter Absolute refresh token life time (seconds)",
        SLIDING_REFRESH_TOKEN_LIFE_TIME: "Sliding refresh token life time (seconds)",
        ENTER_SLIDING_REFRESH_TOKEN_LIFE_TIME: "Enter sliding refresh token life time (seconds)",
        ENTER_NAME: "Enter name",
        ENTER_DESCRIPTION: "Enter description",
        ENTER_CLIENT_URI: "Enter url",
        ENTER_ACCESS_TOKEN_LITE_TIME: "Enter access token life time",
        GRANT_TYPE: "Grant type",
        SELECT_GRANT_TYPE: "Select grant type",
        LOGO_CLIENT: "Logo Client",
        REDIRECT_URI: "Redirect Uri",
        ENTER_REDIRECT_URI: "Enter redirect uri",
        POST_LOGOUT_REDIRECT_URI: "Post logout redirect uri",
        ENTER_POST_LOGOUT_REDIRECT_URI: "Enter post logout redirect uri",
        SCOPE: "Scope",
        SCOPE_NAME: "Scope name",
        ENTER_SCOPE_NAME: "Enter scope name",
        SELECT_SCOPE: "Select scope",
        SCOPE_MORE: "Scope more",
        ACCESS_ROLE_GROUP: "Access role group",
    },

    SERVICE: {
        TITLE_MENU_PARENT: "Service",
        TITLE_MENU: "Service",
        TITLE_LIST_VIEW: "Service",
        TITLE_CREATE_VIEW: "New service",
        TITLE_UPDATE_VIEW: "Update service",
        TITLE_DETAIL_VIEW: "Service",
        BASIC_INFOR: "Service",
        SEARCH_TEXT: "Search by code, name",
        ENTER_SEARCH_TEXT: "Enter code, name",
        LIST_CLIENT: "Clients",
        CODE: "Code",
        NAME: "Name",
        LINK_API: "Link API",
        ENTER_LINK_API: "Enter link API",
        SELECT_SERVICE: "Select service",
        DESCRIPTION: "Description",
        ENTER_CODE: "Enter code",
        ENTER_NAME: "Enter name",
        ENTER_DESCRIPTION: "Enter description",
        CLIENT: "Client",
        SELECT_CLIENT: "Select client",
        TERMS_OF_SERVICE: "Terms of service",
        USER_AGREEMENT: "Agreement",
        USER_POLICY: "Privacy terms",
        ENTER_TERMS_OF_SERVICE: "Enter terms of service",
        ENTER_USER_AGREEMENT: "Enter agreement",
        ENTER_USER_POLICY: "Enter privacy terms",
        DEFAULT_CLIENT: "Client default",
        TYPE_CLIENT: "Client type",
        SELECT_TYPE_CLIENT: "Select client type",
        SERVICE_RELATE_REQUESTS: "Related support services",
        NAME_MODULE: "Module name",
        SELECT_NAME_MODULE: "Select module name",
        CLIENTID_ALREADY_EXISTS: "Client {0} already exists. please choose another client",
        DEFAULT_CLIENT_ALREADY_EXISTS: "Default client already exists",
        SERVICE_ALREADY_EXISTS: "Service {0} already exists. Please choose another service",
        NO_DATA_LIST_CLIENT: "No client added yet",
        INIT_ROLE: "Init Role default",
        CONFIRM_INIT_ROLE: "You definitely want to set the default set of system permissions for the {0} service",
        INIT_ROLE_SUCCESS: "You have successfully added the default permission set for the {0} service",
        INIT_DATA_RESOURCE: "Init data resoure",
        CONFIRM_INIT_DATA_RESOURCE: "You definitely want to set the data for the {0} service",
        INIT_DATA_RESOURCE_SUCCESS: "You have successfully added the data set for the {0} service",

        ACCESS_ROLE_GROUP: "Access role group",
        SELECT_ACCESS_ROLE_GROUP: "Select access role group",
    },

    TENANT: {
        TITLE_MENU: "Tenant",
        TITLE_LIST_VIEW: "Tenant",
        TITLE_CREATE_VIEW: "New tenant",
        TITLE_UPDATE_VIEW: "Update tenant",
        TITLE_DETAIL_VIEW: "Tenant",
        TITLE_INFOR: "Tenant",
        SEARCH_TEXT: "Search by code, name",
        ENTER_SEARCH_TEXT: "Enter code, name",
        CODE: "Code",
        NAME: "Name",
        DESCRIPTION: "Description",
        ENTER_CODE: "Enter code",
        ENTER_NAME: "Enter name",
        ENTER_DESCRIPTION: "Enter description",
        CLIENT_ID: "Client",
        SERVICE_NAME: "Service",
        SELECT_CLIENT_ID: "Select client",
        SELECT_SERVICE_NAME: "Select service",
        DOMAIN_NAME: "Domain name",
        ENTER_DOMAIN_NAME: "Enter domain name",
        NO_TENANT_DETAIL: "No tenant details yet",
        LIST_TENANT_CLIENT: "Clients",
        LOGO_TENANT: "Logo",
        ACCESS_TOKEN: "Access token",
        ENTER_ACCESS_TOKEN: "Enter access token",
        ALREADY_EXIST_CLIENT_ID: "This client already exists",
        SERVICE_AND_CLIENT: "Service and client",
        STATUS: "Status",
        OWNER: "Owner",
        EMAIL_TENANT: "Email",
        ENTER_EMAIL_TENANT: "Enter email",
    },

    CODE_NAME: {
        TITLE_MENU: "Codename",
        TITLE_LIST_VIEW: "Codename",
        TITLE_CREATE_VIEW: "New codename",
        TITLE_UPDATE_VIEW: "Update codename",
        TITLE_DETAIL_VIEW: "Codename",
        BASIC_INFO: "Codename",
        CODE_NAME_DETAIL: "Codename",
        PARENT_GROUP_CODE: "000000",
        CODE: "Code",
        CODE_INPUT: "Enter code",
        INPUT_NAME: "Enter name",
        NAME: "Name",
        SHORT_NAME: "Short name",
        INPUT_SHORT_NAME: "Enter short name",
        SEARCH_TEXT: "Search by name",
        ORGANIZATION_NAME: "Applicable organization",
        SELECT_ORGANIZATION_NAME: "Select organization",
        ALL_ORGANIZATIONS: "All organizations",
        CODE_NAME: "Category group code",
        ENTER_CODE_NAME: "Enter category group code",
        NAME_GROUP: "Category group name",
        ENTER_NAME_GROUP: "Enter category group name",
        NAME_LIST: "Codename list",
        PARENT_GROUP: "Codename parent",
        SEARCH_PARENT_GROUP: "Search codename parent",
        SCOPE_APPLICATION: "Scope of application",
    },

    INVITATION: {
        INVITATION_ORG: "You have an invite to the organization {0} with permission {1}",
        ACCEPT_SUCCESS: "You have successfully accepted the invitation",
        DECLINE_SUCCESS: "You have successfully declined the invitation",
        TITLE_MENU: "Invitation",
        TITLE_LIST_VIEW: "Invitation",
        CONFIRM_ACCEPT: "You definitely want to accept this invitation?",
        CONFIRM_DECLINE: "You definitely want to decline this invitation?",
        CONFIRM_DELETE: "You definitely want to delete this invitation?",
        DELETE_SUCCESS: "You have successfully deleted the invitation",
        CONFIRM_RESEND: "You really want to resend this invitation?",
        RESEND_SUCCESS: "You have successfully resend the invitation",
        SEARCH_TEXT: "Search by email",
        VALID_FROM: "From",
        VALID_TO: "To",
        INVITATION_EXPIRED: "This invitation has expired",
        RESEND: "Resend invitation",
    },

    RESOURCE: {
        TITLE_MENU: "Resource",
        TITLE_LIST_VIEW: "Resource",
        TITLE_UPDATE_VIEW: "Update resource",
        TITLE_DETAIL_VIEW: "Resource",
        BASIC_INFO: "Basic information",
        SEARCH_TEXT: "Search by name, code",

        RESOURCE_DETAIL: "Resource",
        CODE: "Code",
        NAME: "Name",
        NAME_API: "API name",
        ENTER_NAME_API: "Enter API name",
        METHOD: "Method",
        SELECT_METHOD: "Select method",
        EDIT_MODE: "Edit mode",
        SELECT_EDIT_MODE: "Select edit mode",
        DESCRIPTION: "Description",
        ENTER_DESCRIPTION: "Enter description",
    },

    SERVICE_ORGANIZATION: {
        TITLE_MENU: "Management Organization",
        TITLE_LIST_VIEW: "Organization",
        ADD_ORGANIZATIONS: "Add organizations",
        ADD_USERS: "Add users",
        ADD_NEW_ORGANIZATION: "Add new organization",
    },

    LOGIN_LOG: {
        TITLE_MENU: "Login log",
        TITLE_LIST_VIEW: "Login log",
        USER: "User",
        SELECT_USER: "Select user",
        LOGIN_TIME: "Login time",
        LOGOUT_TIME: "Logout time",
        ENTER_START_TIME: "Enter start time",
        ENTER_END_TIME: "Enter end time",
        SEARCH_TEXT: "Search",
        ENTER_SEARCH_TEXT: "Search by key",
        LOCATION: "Location",
        CURRENTLY_LOGGED: "Currently logged",
    },

    POLICY: {
        TITLE_MENU: "Policy",
        TITLE_LIST_VIEW: "Policy",
        TITLE_CREATE_VIEW: "New Policy",
        TITLE_UPDATE_VIEW: "Update Policy",
        TITLE_DETAIL_VIEW: "Policy",
        BASIC_INFO: "Policy",
        SEARCH_TEXT: "Search by code, name",
        ENTER_SEARCH_TEXT: "Enter code, name",

        CODE: "Code",
        NAME: "Name",
        DISCRIPTION: "Description",
        ENTER_CODE: "Enter code",
        ENTER_NAME: "Enter name",
        ENTER_DISCRIPTION: "Enter description",
        QUOTA: "Quota",
        ENTER_QUOTA: "Enter quota",
        PERMISSION: "Permission",
        SELECT_PERMISSION: "Select permission",
        RESOURCE: "Resource",
        SELECT_RESOURCE: "Select resource",
        SERVICE: "Service",
        SELECT_SERVICE: "Select service",
        ROLE: "Role",
        GROUP: "Group User",
        USER: "User",
        OTHER_CLAIM: "Other claim",
        EQUAL: "Equal",
        NOT_EQUAL: "Not equal",
        GREATER_THAN: "Greater than",
        LESS_THAN: "Less than",
        LIST_TARGET: "Targets",
        LIST_RESOURCE: "Resources",
        TARGET: "Target",
        TARGET_TYPE: "Target type",
        SELECT_TARGET: "Select target",
        SELECT_TARGET_TYPE: "Select target type",
        CREATE_SUCCESS: "Create policy success",
        UPDATE_SUCCESS: "Update policy success",
        DELETE_SUCCESS: "Delete policy success",
    },

    EVENT: {
        TITLE_MENU: "Event",
        TITLE_LIST_VIEW: "Event",
        TITLE_UPDATE_VIEW: "Update Event",
        TITLE_DETAIL_VIEW: "Event",
        SEARCH_TEXT: "Search by code, name",
        ENTER_SEARCH_TEXT: "Enter code, name",

        EVENT_CODE: "Code",
        EVENT_NAME: "Name",
        EVENT_TYPE: "Type",
        PROCESS_STATUS: "Process status",
        SELECT_EVENT_TYPE: "Select type",
        SELECT_PROCESS_STATUS: "Select process status",
        SEND: "Send",
        RECEIVE: "Receive",
        PENDING: "Pending",
        PROCESSING: "Processing",
        SUCCESS: "Success",
        FAILURE: "Failure",
        SEND_TIME: "Send time",
        RETRY_COUNT: "Retry count",
        RETRY: "Retry",
        CONFIRM_RETRY: "Would you like to retry again?",
        RETRY_SUCCESS: "Retry success",
    },

    NOTIFICATION: {
        TITLE_MENU: "Notification",
        TITLE_LIST_VIEW: "Notifications",
        TITLE_UPDATE_VIEW: "Update notification",
        TITLE_DETAIL_VIEW: "Detail notification",
        TITLE_CREATE_VIEW: "Add new notification",
    },

    ACCESS_ROLE: {
        SERVICE: "Service",
        TENANT_OR_MEMBERSHIP: "Tenant or membership",
        NORMAL: "Normal",
        USER: "User",
    },
};
