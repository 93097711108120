import { Card, Divider, Grid, IconButton } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Screens from "constants/screens";
import Strings from "constants/strings";
import ServiceService from "services/identity/service.service";
import TenantService from "services/identity/tenant.service";

import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    ControlLabelCheckBox,
    CustomIcon,
    FormField,
    ImageUploader,
    RoleType,
    Typography,
    useCommonComponentContext,
} from "@maysoft/common-component-react";
import { ICodename, IService, IServiceClient, ITenant, ITenantClient, ITitleRoute } from "commons/interfaces";
import { IsTrue, Mode, Status } from "constants/enum";
import { DashboardLayout } from "layout";
import { RootState } from "store";
import { showLoading } from "store/slice/loadingAPI.slice";
import { setDataAlert } from "store/slice/message.slice";
import { clientItem } from "./styles";

type IDataEdit = Omit<ITenant, "serviceClients" | "owner"> & {
    serviceCodes: string[];
    owner: string;
};

const tenantService = new TenantService();
const serviceService = new ServiceService();

type IError = { [k in keyof IDataEdit]?: string };

interface IServiceList extends IService {
    collapse: boolean;
}

interface IModel {
    mode?: number;
    title?: string;
    route?: ITitleRoute[];
}

const TenantEditScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const id = searchParams.get("id");
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const [model, setModel] = useState<IModel>({});
    const [dataEdit, setDataEdit] = useState<IDataEdit>({
        serviceCodes: [],
        owner: undefined,
        tenantCode: undefined,
        name: undefined,
        description: undefined,
        logoId: undefined,
        logoUrl: undefined,
        id: undefined,
        status: undefined,
        createTime: undefined,
        createUser: undefined,
        updateTime: undefined,
        updateUser: undefined,
    });

    const [error, setError] = useState<IError>({} as IError);
    const [serviceList, setServiceList] = useState<IServiceList[]>([]);
    const [serviceClients, setServiceClients] = useState<IServiceClient[]>([]);
    const serviceClientsRef = useRef<IServiceClient[]>(null);
    const dataRef = useRef<IDataEdit>(null);

    const isEdited = JSON.stringify(dataRef.current) !== JSON.stringify(dataEdit);

    const serviceCodeList = useMemo(() => serviceList.map((el) => ({ code: el.serviceCode, name: el.serviceName })), [serviceList]);
    const listAccessRoleGroup: ICodename[] = [
        { code: RoleType.Service, name: Strings.ACCESS_ROLE.SERVICE },
        { code: RoleType.TenantOrMembership, name: Strings.ACCESS_ROLE.TENANT_OR_MEMBERSHIP },
        { code: RoleType.Normal, name: Strings.ACCESS_ROLE.NORMAL },
        { code: RoleType.User, name: Strings.ACCESS_ROLE.USER },
    ];

    const [ownerSelected, setOwnerSelected] = useState<{
        avatarUrl?: string;
        avatarId?: string;
        email?: string;
        fullName?: string;
        identityId?: string;
        phoneNumber?: string;
    }>({});

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);
    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.MANAGE_TENANT);

    useEffect(() => {
        getData();
    }, []);

    const handleGoBack = () => {
        const itemP = listPathName.find((el) => el.pathName === Screens.TENANT_LIST);
        navigate(Screens.TENANT_LIST + (itemP ? itemP.query : ""));
    };

    const getAllService = async () => {
        const resultService = await serviceService.getAll();
        setServiceList(resultService.map((item) => ({ ...item, collapse: false })));
    };

    const getDetail = async () => {
        if (Helpers.isNullOrEmpty(id)) {
            setModel({
                mode: Mode.Create,
                title: Strings.TENANT.TITLE_CREATE_VIEW,
                route: [
                    { title: Strings.TENANT.TITLE_LIST_VIEW, route: Screens.TENANT_LIST },
                    { title: Strings.Common.ADD_NEW, route: "" },
                ],
            });

            setOwnerSelected(undefined);

            // setDataEdit({ status: Status.Active });
        } else {
            const result = await tenantService.getDetail(id);
            const temp = {
                ...result,
                owner: result.owner?.email,
                serviceCodes: result?.serviceClients.map((el) => el.serviceCode) || [],
            };
            setDataEdit({ ...temp });
            dataRef.current = JSON.parse(JSON.stringify({ ...temp }));

            setServiceClients(result.serviceClients || []);
            serviceClientsRef.current = JSON.parse(JSON.stringify([...result.serviceClients]));

            setOwnerSelected({
                identityId: result.owner?.identityId,
                email: result.owner?.email,
                fullName: result.owner?.fullName,
                avatarId: result.owner?.avatarId,
                avatarUrl: result.owner?.avatarUrl,
                phoneNumber: result.owner?.phoneNumber,
            });

            const mode = resourcePermissions.canUpdate ? pramsMode || Mode.Update : Mode.View;

            setModel({
                mode: mode,
                title: mode === Mode.View ? Strings.TENANT.TITLE_DETAIL_VIEW : Strings.TENANT.TITLE_UPDATE_VIEW,
                route: [
                    { title: Strings.TENANT.TITLE_LIST_VIEW, route: Screens.TENANT_LIST },
                    {
                        title: mode === Mode.View ? Strings.Common.VIEW : Strings.Common.UPDATE,
                        route: "",
                    },
                ],
            });

            navigate(Screens.TENANT_EDIT + `?id=${id}&mode=${mode}`, { replace: true });
        }
    };

    const getData = async () => {
        try {
            dispatch(showLoading(true));

            await getAllService();
            await getDetail();
        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        } finally {
            dispatch(showLoading(false));
        }
    };

    const checkValidate = () => {
        let checked: boolean = true;
        let newError: any = { ...error };
        let keys = ["name", "tenantCode", "owner"];

        keys.forEach((key) => {
            let objectKey = key as keyof IDataEdit;
            if (Helpers.isNullOrEmpty(dataEdit[objectKey])) {
                newError[key] = Strings.Validation.REQUIRED;
                checked = false;
            }
        });

        if (Helpers.isNullOrEmpty(dataEdit["serviceCodes"]) || dataEdit["serviceCodes"].length === 0) {
            newError["serviceCodes"] = Strings.Validation.REQUIRED;
            checked = false;
        }

        if (!checked) {
            dispatch(setDataAlert({ message: Strings.Message.PLEASE_COMPLETE_ALL_INFORMATION, type: "error" }));
        }
        setError(newError);
        return checked;
    };

    const onSubmit = async () => {
        if (model.mode === Mode.View) {
            setModel({
                ...model,
                mode: Mode.Update,
                title: Strings.TENANT.TITLE_UPDATE_VIEW,
                route: [
                    { title: Strings.TENANT.TITLE_LIST_VIEW, route: Screens.TENANT_LIST },
                    { title: Strings.Common.UPDATE, route: "" },
                ],
            });
            navigate(Screens.TENANT_EDIT + `?id=${id}&mode=${Mode.Update}`, { replace: true });
            return;
        }

        try {
            if (!checkValidate()) return;
            dispatch(showLoading(true));

            const dataSubmit = {
                name: dataEdit?.name,
                tenantCode: dataEdit?.tenantCode,
                description: dataEdit?.description,
                status: dataEdit?.status,
                logoId: dataEdit?.logoId,
                logoUrl: dataEdit?.logoUrl,
                owner: dataEdit?.owner,
                serviceCodes: dataEdit?.serviceCodes,
                id: dataEdit?.id,
                updateTime: dataEdit?.updateTime,
            };

            if (Helpers.isNullOrEmpty(dataSubmit.id)) {
                await tenantService.create(dataSubmit);
                dispatch(setDataAlert({ message: Strings.Message.CREATE_SUCCESS, type: "success" }));
                handleGoBack();
            } else {
                await tenantService.update(dataSubmit);
                await getDetail();
                setModel({
                    ...model,
                    mode: Mode.View,
                    title: Strings.TENANT.TITLE_DETAIL_VIEW,
                    route: [
                        { title: Strings.TENANT.TITLE_LIST_VIEW, route: Screens.TENANT_LIST },
                        { title: Strings.Common.VIEW, route: "" },
                    ],
                });
                navigate(Screens.TENANT_EDIT + `?id=${id}&mode=${Mode.View}`, { replace: true });
                dispatch(setDataAlert({ message: Strings.Message.UPDATE_SUCCESS, type: "success" }));
            }
        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        } finally {
            dispatch(showLoading(false));
        }
    };

    //     switch (key) {
    //         case "logoId":
    //             setDataEdit((prev) => ({
    //                 ...prev,
    //                 isEdit: true,
    //                 logoId: value?.avatarId,
    //                 logoUrl: value?.avatarUrl,
    //             }));
    //             break;
    //         case "serviceClients":
    //             const { serviceCode, clientId } = value;

    //             let serviceClientsTemp = [...(dataEdit?.serviceClients || [])];
    //             let index = serviceClientsTemp.findIndex((item) => item.serviceCode === serviceCode);

    //             if (index !== -1 && Helpers.isNullOrEmpty(clientId)) {
    //                 serviceClientsTemp = serviceClientsTemp.filter((item) => item.serviceCode !== serviceCode);
    //                 setDataEdit((prev) => ({
    //                     ...prev,
    //                     isEdit: true,
    //                     serviceClients: serviceClientsTemp,
    //                 }));
    //                 break;
    //             }

    //             if (index === -1) {
    //                 serviceClientsTemp.push({
    //                     serviceCode,
    //                     tenantClients: [{ clientId: clientId }],
    //                 });
    //                 setDataEdit((prev) => ({
    //                     ...prev,
    //                     isEdit: true,
    //                     serviceClients: serviceClientsTemp,
    //                 }));
    //                 break;
    //             }

    //             let tenantClientsTemp = [...(serviceClientsTemp[index]?.tenantClients || [])];
    //             let clientIndex = tenantClientsTemp?.findIndex((item) => item.clientId === clientId);

    //             if (clientIndex === -1) {
    //                 tenantClientsTemp = [...tenantClientsTemp, { clientId: clientId }];
    //             } else {
    //                 tenantClientsTemp = tenantClientsTemp?.filter((item) => item.clientId !== clientId);
    //             }

    //             serviceClientsTemp[index].tenantClients = tenantClientsTemp;

    //             setDataEdit((prev) => ({
    //                 ...prev,
    //                 isEdit: true,
    //                 serviceClients: serviceClientsTemp,
    //             }));

    //             break;
    //         default:
    //             setDataEdit((prev) => ({
    //                 ...prev,
    //                 [key]: value,
    //                 isEdit: true,
    //             }));
    //             break;
    //     }

    //     if (!Helpers.isNullOrEmpty(error[key])) {
    //         setError((prev) => ({ ...prev, [key]: "" }));
    //     }
    // };
    const onChangeValue =
        <T extends keyof IDataEdit>(key: T) =>
        (value: IDataEdit[T]) => {
            setDataEdit((prev) => ({ ...prev, [key]: value }));

            if (!Helpers.isNullOrEmpty(error[key])) {
                setError((prev) => ({ ...prev, [key]: "" }));
            }
        };

    const AvatarCard = () => (
        <Card>
            <Box p={2} textAlign="center">
                <ImageUploader
                    width={150}
                    height={150}
                    imageRatio="1:1"
                    fileId={dataEdit?.logoId}
                    disabled={model.mode === Mode.View}
                    onChangeImage={(data) => {
                        if (Helpers.isNullOrEmpty(data?.accessUrl) && Helpers.isNullOrEmpty(data?.id)) {
                            return;
                        } else {
                            onChangeValue("logoId")(data?.id);
                            onChangeValue("logoUrl")(data?.accessUrl);
                        }
                    }}
                />
                <Typography variant="h6">{Strings.TENANT.LOGO_TENANT}</Typography>
            </Box>
        </Card>
    );

    const InfoCard = () => (
        <Card>
            <Box p={2}>
                <Typography variant="h5">{Strings.TENANT.TITLE_INFOR}</Typography>
                <Box p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormField
                                required
                                maxLength={6}
                                mode={model.mode}
                                label={Strings.TENANT.CODE}
                                placeholder={Strings.TENANT.ENTER_CODE}
                                errorMessage={error?.tenantCode || ""}
                                defaultValue={dataEdit?.tenantCode || ""}
                                disabled={!Helpers.isNullOrEmpty(dataEdit?.id)}
                                onBlur={onChangeValue("tenantCode")}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormField
                                required
                                maxLength={255}
                                mode={model.mode}
                                label={Strings.TENANT.NAME}
                                placeholder={Strings.TENANT.ENTER_NAME}
                                errorMessage={error?.name || ""}
                                defaultValue={dataEdit?.name || ""}
                                onBlur={onChangeValue("name")}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormField
                                required
                                maxLength={255}
                                mode={model.mode}
                                errorMessage={error?.owner || ""}
                                defaultValue={dataEdit?.owner || ""}
                                label={Strings.TENANT.EMAIL_TENANT}
                                placeholder={Strings.TENANT.ENTER_EMAIL_TENANT}
                                disabled={!Helpers.isNullOrEmpty(dataEdit?.id) && !Helpers.isNullOrEmpty(ownerSelected?.identityId)}
                                onBlur={onChangeValue("owner")}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormField
                                multiline
                                maxLength={500}
                                mode={model.mode}
                                label={Strings.TENANT.DESCRIPTION}
                                defaultValue={dataEdit?.description || ""}
                                errorMessage={error?.description || ""}
                                placeholder={Strings.TENANT.ENTER_DESCRIPTION}
                                onBlur={onChangeValue("description")}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                multiple
                                required
                                data={serviceCodeList}
                                mode={model.mode}
                                optionDisabled={serviceClients.map((el) => el.serviceCode)}
                                label={Strings.TENANT.SERVICE_NAME}
                                defaultValue={dataEdit?.serviceCodes || ""}
                                errorMessage={error?.serviceCodes || ""}
                                placeholder={Strings.TENANT.SELECT_SERVICE_NAME}
                                onChange={onChangeValue("serviceCodes")}
                            />
                        </Grid>

                        {!Helpers.isNullOrEmpty(dataEdit?.id) && !Helpers.isNullOrEmpty(ownerSelected?.identityId) && (
                            <Grid item xs={12} md={12}>
                                <Box sx={{ display: "grid" }}>
                                    <Box display="flex" alignItems="center" marginBottom={1}>
                                        <Typography variant="h6">{Strings.TENANT.OWNER}</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            padding: "8px",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            borderRadius: "8px",
                                            marginBottom: "8px",
                                            alignItems: "center",
                                            border: "1px #dddddd solid",
                                        }}
                                    >
                                        <Avatar
                                            alt="avt"
                                            src={ownerSelected.avatarUrl}
                                            text={ownerSelected?.fullName || ownerSelected?.email}
                                            sx={{
                                                width: 50,
                                                height: 50,
                                                marginLeft: "16px",
                                                marginRight: "16px",
                                                border: "1px #dddddd solid",
                                            }}
                                        />
                                        <Box display="inline-grid">
                                            <Typography variant="button" fontWeight="bold">
                                                {ownerSelected?.fullName || ownerSelected?.email || "No Name"}
                                            </Typography>
                                            <Typography variant="button" color="secondary">
                                                {"Sđt"}:&nbsp;{ownerSelected?.phoneNumber}
                                            </Typography>
                                            <Typography variant="button" color="secondary">
                                                {"Email"}:&nbsp;{ownerSelected?.email}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Box>
        </Card>
    );

    const onChangeServiceClient =
        <T extends keyof ITenantClient>(key: T) =>
        (value: ITenantClient[T], index: number, subIndex: number) => {
            setServiceClients((prev) => {
                const temp = [...prev];
                if ("defaultClient" === key) {
                    for (let i = 0; i < temp[index]["tenantClients"].length; i++) {
                        temp[index]["tenantClients"][i]["defaultClient"] = IsTrue.False;
                    }
                }
                temp[index]["tenantClients"][subIndex][key] = value;
                return temp;
            });
        };

    const onUpdateServiceClients = async () => {
        try {
            dispatch(showLoading(true));
            const requestData = {
                tenantCode: dataEdit?.tenantCode,
                serviceClients: serviceClients.reduce((acc, cur) => {
                    cur.tenantClients.forEach((el) => {
                        acc.push({
                            id: el.id,
                            clientId: el.clientId,
                            clientType: el.clientType,
                            defaultClient: el.defaultClient,
                            accessRoleGroup: el.accessRoleGroup,
                            status: Status.Active,
                            serviceCode: cur.serviceCode,
                        });
                    });
                    return acc;
                }, []),
            };

            await tenantService.updateTenantServiceClient(requestData);
            serviceClientsRef.current = JSON.parse(JSON.stringify([...serviceClients]));

            dispatch(setDataAlert({ message: Strings.Message.UPDATE_SUCCESS, type: "success" }));
        } catch (error) {
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        } finally {
            dispatch(showLoading(false));
        }
    };

    return (
        <DashboardLayout
            title={model.title}
            route={model.route || []}
            isPermission={resourcePermissions.canRead}
            onBackPress={() => {
                if (isEdited) {
                    handleGoBack();
                } else {
                    Helpers.showConfirmAlert(Strings.Message.CANCEL_ACTION_MESSAGE, handleGoBack);
                }
            }}
            nameActionPress={model.mode === Mode.View ? Strings.Common.EDIT : Strings.Common.SAVE}
            onActionPress={resourcePermissions.canUpdate || resourcePermissions.canCreate ? onSubmit : undefined}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={5} md={4} lg={3}>
                    <AvatarCard />
                </Grid>
                <Grid item xs={12} sm={7} md={8} lg={9}>
                    <Box>
                        <InfoCard />
                    </Box>
                    {!Helpers.isNullOrEmpty(dataEdit?.id) && (
                        <Box paddingTop={3}>
                            <ServiceClientsCard
                                serviceClients={serviceClients}
                                serviceCodeList={serviceCodeList}
                                mode={model.mode}
                                listAccessRoleGroup={listAccessRoleGroup}
                                onChangeServiceClient={onChangeServiceClient}
                                onSubmit={onUpdateServiceClients}
                                isEdited={JSON.stringify(serviceClientsRef.current) !== JSON.stringify(serviceClients)}
                            />
                        </Box>
                    )}
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

const ServiceClientsCard = ({
    serviceClients,
    serviceCodeList,
    mode,
    isEdited,
    listAccessRoleGroup,
    onChangeServiceClient,
    onSubmit,
}: {
    mode: Mode;
    isEdited: boolean;
    listAccessRoleGroup: ICodename[];
    serviceCodeList: ICodename[];
    serviceClients: IServiceClient[];
    onChangeServiceClient: <T extends keyof ITenantClient>(key: T) => (value: ITenantClient[T], index: number, subIndex: number) => void;
    onSubmit: () => Promise<void>;
}) => {
    return (
        <Card>
            <Box p={2}>
                <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                    <Typography variant="h5">{Strings.TENANT.SERVICE_AND_CLIENT}</Typography>
                    <Button onClick={onSubmit} disabled={!isEdited}>
                        {Strings.Common.UPDATE}
                    </Button>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                    {serviceClients.map((item, index: number) => {
                        const serviceName = serviceCodeList.find((el) => el.code === item.serviceCode)?.name;
                        return (
                            <Box
                                key={index}
                                sx={{
                                    position: "relative",
                                }}
                            >
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Box display="flex" alignItems="center">
                                        <ControlLabelCheckBox
                                            label=""
                                            disabled={mode === Mode.View}
                                            value={true}
                                            onChangeValue={(value) => {
                                                // onChangeValue({ serviceCode: item.serviceCode }, "serviceClients");
                                            }}
                                        />
                                        <Typography variant="button">{serviceName}</Typography>
                                    </Box>
                                    <IconButton onClick={() => {}}>
                                        {/* {true ? <ExpandLess /> : <ExpandMore />} */}
                                        {/* {item.collapse ? <ExpandLess /> : <ExpandMore />} */}
                                    </IconButton>
                                </Box>
                                {/* {item?.collapse && ( */}
                                {true && (
                                    <Box>
                                        {item.tenantClients.map((tenantClient, subIndex: number) => {
                                            // let itemTenantClient = [...(itemServiceClient?.tenantClients || [])].find((el) =>
                                            //     el.clientId?.includes(clientId)
                                            // );
                                            // if (itemTenantClient) {
                                            //     count += 1;
                                            // }
                                            const checked = tenantClient.status === Status.Active;
                                            return (
                                                <Box
                                                    key={subIndex}
                                                    sx={(theme) =>
                                                        clientItem(theme, {
                                                            index: subIndex,
                                                            line: 0,
                                                            // line: 1,
                                                            length: item.tenantClients.length - 1,
                                                            checked: checked,
                                                            collapse: true,
                                                        })
                                                    }
                                                >
                                                    <Box
                                                        gap={1}
                                                        display="flex"
                                                        alignItems="center"
                                                        paddingRight={"24px"}
                                                        justifyContent="space-between"
                                                    >
                                                        <Box display="flex" alignItems="center" gap={1}>
                                                            <Box display="flex" alignItems="center">
                                                                <ControlLabelCheckBox
                                                                    label=""
                                                                    disabled={mode === Mode.View || tenantClient.defaultClient === IsTrue.True}
                                                                    value={tenantClient.status === Status.Active}
                                                                    onChangeValue={() => {
                                                                        onChangeServiceClient("status")(
                                                                            checked ? Status.Inactive : Status.Active,
                                                                            index,
                                                                            subIndex
                                                                        );
                                                                    }}
                                                                />
                                                                <Typography variant="button">{tenantClient.clientId}</Typography>
                                                            </Box>
                                                            {tenantClient.defaultClient === IsTrue.True && (
                                                                <Typography
                                                                    variant="caption"
                                                                    sx={({ palette: { info }, functions: { rgba } }) => ({
                                                                        borderRadius: 2,
                                                                        border: `1px solid ${info.main}`,
                                                                        backgroundColor: rgba(info.main, 0.1),
                                                                        px: 2,
                                                                        py: 0.5,
                                                                        color: info.main,
                                                                    })}
                                                                >
                                                                    {Strings.Common.DEFAULT}
                                                                </Typography>
                                                            )}
                                                        </Box>

                                                        {tenantClient.defaultClient !== IsTrue.True && (
                                                            <IconButton
                                                                onClick={() => {
                                                                    onChangeServiceClient("defaultClient")(IsTrue.True, index, subIndex);
                                                                }}
                                                            >
                                                                <CustomIcon iconName="check" />
                                                            </IconButton>
                                                        )}
                                                    </Box>
                                                    {true && (
                                                        // {itemTenantClient?.collapse && !Helpers.isNullOrEmpty(itemTenantClient) && (
                                                        <Box ml={2} overflow="hidden">
                                                            {/* <Typography variant="caption" fontWeight="bold">{Strings.CLIENT.ACCESS_ROLE_GROUP}</Typography> */}
                                                            {listAccessRoleGroup.map((role, roleIndex) => {
                                                                const roleCheck =
                                                                    ((tenantClient?.accessRoleGroup || 0) & Number(role.code)) === Number(role.code);

                                                                return (
                                                                    <Box
                                                                        display="flex"
                                                                        alignItems="center"
                                                                        sx={(theme) =>
                                                                            clientItem(theme, {
                                                                                index: roleIndex,
                                                                                line: 0,
                                                                                // line: 1,
                                                                                length: listAccessRoleGroup.length - 1,
                                                                                checked: roleCheck,
                                                                            })
                                                                        }
                                                                    >
                                                                        <Box display="flex" alignItems="center">
                                                                            <ControlLabelCheckBox
                                                                                label=""
                                                                                value={roleCheck}
                                                                                disabled={mode === Mode.View}
                                                                                onChangeValue={() => {
                                                                                    onChangeServiceClient("accessRoleGroup")(
                                                                                        (tenantClient?.accessRoleGroup & Number(role.code)) ===
                                                                                            Number(role.code)
                                                                                            ? tenantClient?.accessRoleGroup ^ Number(role.code)
                                                                                            : tenantClient?.accessRoleGroup | Number(role.code),
                                                                                        index,
                                                                                        subIndex
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <Typography variant="caption">{role.name}</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                );
                                                            })}
                                                        </Box>
                                                    )}
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                )}
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Card>
    );
};

export default TenantEditScreen;
