const ASSET_API_URL = process.env.REACT_APP_ASSET_API_URL || "";
const COMMON_API_URL = process.env.REACT_APP_COMMON_API_URL || "";
const MESSAGE_API_URL = process.env.REACT_APP_MESSAGE_API_URL || "";
const IDENTITY_URL = process.env.REACT_APP_IDENTITY_URL || "";
const IDENTITY_API_URL = `${IDENTITY_URL}/api`;

export { IDENTITY_URL, IDENTITY_API_URL, ASSET_API_URL, COMMON_API_URL, MESSAGE_API_URL }

const Constants = {
    Api: {
        TIMEOUT: 60 * 1000,
    },

    /**
     * Return code from Api
     */
    ApiCode: {
        // Code from server api
        SUCCESS: 200,
        BAD_REQUEST: 400,
        TOKEN_EXPRIED: 401,
        NOT_PERMISSION: 403,
        NOT_FOUND: 404,
        INTERNAL_SERVER_ERROR: 500,

        // Code from local app
        INTERNAL_SERVER: "INTERNAL_SERVER",
        UNKNOWN_NETWORK: "UNKNOWN_NETWORK",
        CONNECTION_TIMEOUT: "CONNECTION_TIMEOUT",
        INVALID_USERNAME_OR_PASSWORD: "invalid_username_or_password"
    },

    /**
     * Cloud Storage config
     */
    CloudStorage: {
        TEMP_FOLDER: "temp",
        AVATAR_FOLDER: "avatar",
        ORGANIZATION_IMAGE_FOLDER: "org_image",
        EDITOR_CONTENT_FOLDER: "editor_content",
    },

    IdentityPath: {
        REDIRECT_URI: "/auth/callback",
        POST_LOGOUT_REDIRECT_URL: "/",
    },

    /**
     * Setting path for Api
     */
    ApiPath: {
        /** 
         * Api path IDENTITY
         */

        // auth
        ACCOUNT: {
            SIGNIN: "/Account/SignIn",
            SIGNUP: "/Account/SignUp",
            LOGOUT: "/Account/Logout",
            REFRESH_TOKEN: "Account/RefreshToken",
            RESSET_PASSWORD: "/Account/ResetPassword",
            FORGOT_PASSWORD: "/Account/ForgotPassword",
            RESET_PASSWORD: "/Account/ResetPassword",
        },

        // notification
        NOTIFICATION: {
            GET_PAGED: "/Message/GetPaged",
            READ_MESSAGE: "/Message/ReadMessage",
            DETAIL_MESSAGE: "/Message/DetailMessage",
            READ_ALL_MESSAGE: "/Message/ReadAllMessage",
            DELETE_ALL_MESSAGE: "/Message/DeleteAllMessage",
            REGISTER: "/Message/Register",
            UN_REGISTER: "/Message/Unregister",
        },

        /** 
        * Api path COMMON
        */
        //Upload File
        UPLOAD_FILE: "/file/upload",
        GET_FILE: "/file",


        LOGIN_LOG: {
            GET_PAGED: "/LoginLog/GetPaged",
        },

        // PROFILE
        PROFILE: {
            USER_INFO: "Profile/UserInfo",
            USER_AUTHORIZATION: "Profile/UserAuthorization",
            UPDATE_ORGANIZATION_PROFILE: "Profile/UpdateOrganizationProfile",
            UPDATE_USER_PROFILE: "Profile/UpdateUserProfile",
            UPDATE_AVATAR: "Profile/ChangeAvatar",
            CHANGE_PASSWORD: "Profile/ChangePassword",
            CHANGE_PHONE_NUMBER: "Profile/ChangePhoneNumber",
            GET_CHANGE_PHONE_NUMBER_OTP: "Profile/GetChangePhoneNumberOTP",
            GET_CHANGE_EMAIL_OTP: "Profile/GetChangeEmailOTP",
            CHANGE_EMAIL: "Profile/ChangeEmail",
            USER_SUMMARY: "Profile/UserSummary",
        },

        // ORGANIZATION
        ORGANIZATION: {
            GET_ALL: "/Organization/GetAll",
            GET_PAGED: "/Organization/GetPaged",
            GET_DETAIL_BY_ID: "/Organization/Detail",
            CREATE: "/Organization/Create",
            UPDATE: "/Organization/Update",
            DELETE: "/Organization/Delete",
            UPDATE_STATUS: "/Organization/UpdateStatus",
            OWNED_ORGANIZATION: "/Organization/OwnedOrganization",
        },

        // SERVICE ORGANIZATION
        SERVICE_ORGANIZATION: {
            ADD: "/ServiceOrganization/Add",
        },


        // GROUP 
        GROUP: {
            GET_PAGED: "/Group/GetPaged",
            GET_ALL: "/Group/GetAll",
            GET_DETAIL: "/Group/Detail",
            CREATE: "/Group/Create",
            UPDATE: "/Group/Update",
            DELETE: "/Group/Delete",
            UPDATE_STATUS: "/Group/UpdateStatus",
        },

        // USER
        USER: {
            USER_INFO: "/User/getUserInfo",
            GET_PAGED: "/User/GetPaged",
            CREATE: "/User/Create",
            UPDATE: "/User/Update",
            DETAIL: "/User/Detail",
            BLOCK: "/User/Block",
            DELETE: "/User/Delete",
            EXPORT: "User/ExportData",
            EXPORT_DATA: "/User/ExportData",
            UPDATE_STATUS: "/User/UpdateStatus",
            GET_MANAGE_USER: "/User/GetManageUser",
            GET_SERVICE_USER_PAGED: "User/GetServiceUserPaged",
        },

        USER_ROLE: {
            UPDATE: "/UserRole/Update",
        },

        GROUP_USER: {
            GET_DETAIL: "/GroupUser/GetDetail",
            CREATE: "/GroupUser/CreateGroupUser",
            UPDATE: "/GroupUser/UpdateGroupUser",
            DELETE: "/GroupUser/DeleteGroupUser",
        },

        USER_CONTACT: {
            GET_DEFAULT: "/UserContact/GetDefault",
            CREATE: "/UserContact/Create",
            UPDATE: "/UserContact/Update",
        },

        // RESOURCE
        RESOURCE: {
            GET_ALL: "/Resource/GetAll",
            GET_TEMPLATE: "/Resource/GetTemplate",
            GET_PAGED: "/Resource/GetPaged",
            GET_DETAIL: "/Resource/Detail",
            CREATE: "/Resource/Create",
            UPDATE: "/Resource/Update",
            DELETE: "/Resource/Delete",
            INIT_DATA: "/Resource/InitData",
            GET_DEFAULT_RESOURCE_DETAIL: "/Resource/GetDefaultResourceDetail",
        },

        // ROLE
        ROLE: {
            GET_ALL: "/Role/GetAll",
            GET_PAGED: "/Role/GetPaged",
            GET_DETAIL: "/Role/Detail",
            CREATE: "/Role/Create",
            UPDATE: "/Role/Update",
            DELETE: "/Role/Delete",
            UPDATE_STATUS: "/Role/UpdateStatus",
        },

        // POLICY
        POLICY: {
            GET_ALL: "/Policy/GetAll",
            GET_PAGED: "/Policy/GetPaged",
            GET_DETAIL: "/Policy/Detail",
            CREATE: "/Policy/Create",
            UPDATE: "/Policy/Update",
            DELETE: "/Policy/Delete",
            REINIT_USER_RESOURCE: "/Policy/ReinitUserResource",
            REINIT_GROUP_RESOURCE: "/Policy/ReinitGroupResource",
        },

        // MENU
        MENU: {
            GET_ALL: "/Menu/GetAll",
            GET_PAGED: "/Menu/GetPaged",
            GET_DETAIL: "/Menu/Detail",
            CREATE: "/Menu/Create",
            UPDATE: "/Menu/Update",
            DELETE: "/Menu/Delete",
        },

        // ASSET
        ASSET: {
            GET_ASSET_INFO: "/GetAssetInfo",
            GET_ASSET_ACCESS_URL: "/GetAccessUrl",
            GET_ASSET_DOWNLOAD_URL: "/GetDownloadUrl",
            GET_EXTERNAL_URL_IDS: "/GetExternalUrlIds",
            CREATE_URL_ASSET: "/CreateUrlAsset",
        },

        CLIENT: {
            GET_ALL: "/Client/GetAll",
            GET_PAGED: "/Client/GetPaged",
            GET_DETAIL: "/Client/Detail",
            CREATE: "/Client/Create",
            UPDATE: "/Client/Update",
            DELETE: "/Client/Delete",
        },

        // Service
        SERVICE: {
            GET_ALL: "/Service/GetAll",
            GET_PAGED: "/Service/GetPaged",
            GET_DETAIL: "/Service/Detail",
            CREATE: "/Service/Create",
            UPDATE: "/Service/Update",
            DELETE: "/Service/Delete",
            INIT_ROLE: "/Service/InitRole",
        },

        // Tenant
        TENANT: {
            GET_ALL: "/Tenant/GetAll",
            GET_PAGED: "/Tenant/GetPaged",
            GET_DETAIL: "/Tenant/Detail",
            CREATE: "/Tenant/Create",
            UPDATE: "/Tenant/Update",
            UPDATE_TENANT_SERVICE_CLIENT: "/Tenant/UpdateTenantServiceClient",
            DELETE: "/Tenant/Delete",
        },

        // Codename
        CODENAME: {
            GET_BY_GROUP: "/Codename/GetByGroup",
            GET_PAGED: "/Codename/GetPaged",
            DELETE: "/Codename/Delete",
            GET_DETAIL: "/Codename/Detail",
            CREATE: "/Codename/Create",
            UPDATE: "/Codename/Update",
        },

        INVITATION: {
            GET_ALL: "/Invitation/GetAll",
            ACCEPT: "/Invitation/Accept",
            DECLINE: "/Invitation/Decline",
            CREATE: "/Invitation/create",
            DELETE: "/Invitation/delete",
            RESEND: "/Invitation/Resend",
            GET_PAGED: "/Invitation/GetPaged",
            QUICK_INVITE: "/Invitation/QuickInvite"
        },

        // Address
        ADDRESS: {
            CREATE: "/Address/Create",
            UPDATE: "/Address/Update",
            DELETE: "/Address/Delete",
            DETAIL: "/Address/Detail",
            ADDRESS_BY_IDS: "/Address/GetByIds",
        },

        // Administrative Division
        ADMINISTRATIVE_DIVISION: {
            GET_PAGED: "/AdministrativeDivision/GetPaged",
            GET_CITY: "/AdministrativeDivision/Cities",
            GET_LIST_BY_PARENT_ID: "/AdministrativeDivision/GetListByParentId",
            GET_DETAIL: "/AdministrativeDivision/Detail",
            DELETE: "/AdministrativeDivision/Delete",
            CREATE: "AdministrativeDivision/Create",
            UPDATE: "AdministrativeDivision/Update",
        },

        SYSTEM_SETTING: {
            UPDATE: "/SystemSetting/Update",
            GET_BY_SETTINGCODE: "/SystemSetting/GetBySettingCode",
            GET_SYSTEM_SETTING: "/SystemSetting/GetSystemSetting",
            UPDATE_MULTI: "/SystemSetting/UpdateMulti",
            GET_BY_SETTING_CODES: "/SystemSetting/GetBySettingCodes",
        },

        // Event
        EVENT: {
            GET_PAGED: "/Event/GetPaged",
            UPDATE: "/Event/Update",
            RETRY: "/Event/Retry",
        },
    },


    /**
     * Styles for app.
     *
     * Color refer
     * @see https://www.rapidtables.com/web/color/index.html
     * @see https://www.w3schools.com/w3css/w3css_colors.asp
     */
    Styles: {
        // =====================================================================
        // Common color
        // =====================================================================
        BLUE_COLOR: "#3b82f6",
        RED_COLOR: "#dc2626",
        GRAY_COLOR: "#808080",
        BLACK_COLOR: "#000000",
        GREEN_COLOR: "#008000",
        WHITE_COLOR: "#FFFFFF",
        PRIMARY_COLOR: "#222224",
        SECONDARY_COLOR: "#7b809a",
        OCEAN_BLUE_COLOR: "#174291",
        LIGHT_BLUE_COLOR: "#12B7BC",
        LIGHT_GRAY_COLOR: "#e5e7eb",
        BRIGHT_OCEAN_BLUE_COLOR: "#1F5DC6",
        PRIMARY_DARK_COLOR: "rgb(13, 142, 147)",

        // New - Analysis - Processing - Processed - Cancelled - Close
        STATUS_COLOR: [
            "#27AE60",
            "#FEC600",
            "#24EBC7",
            "#00AFF0",
            "#D3D3D3",
            "#CED4DA",
        ],

        // =====================================================================
        // Console log style
        // Color refer at: https://www.w3schools.com/w3css/w3css_colors.asp
        // =====================================================================
        CONSOLE_LOG_DONE_ERROR: "border: 2px solid #F44336; color: #000000", // Red
        CONSOLE_LOG_DONE_SUCCESS: "border: 2px solid #4CAF50; color: #000000", // Green
        CONSOLE_LOG_ERROR: "background: #F44336; color: #FFFFFF", // Red
        CONSOLE_LOG_NOTICE: "background: #FF9800; color: #000000; font-size: x-large", // Orange
        CONSOLE_LOG_PREPARE: "border: 2px solid #2196F3; color: #000000", // Blue
        CONSOLE_LOG_START: "background: #2196F3; color: #FFFFFF", // Blue
        CONSOLE_LOG_SUCCESS: "background: #4CAF50; color: #FFFFFF", // Green

        // =====================================================================
        // Common size
        // =====================================================================
        AVATAR_SIZE: "80px",
        DEFAULT_FONTSIZE: "16px",
        DEFAULT_SPACING: "24px",

        // =====================================================================
        // Font size
        // =====================================================================
        FONT_SIZE_SMALL: 13,
        FONT_SIZE_DEFAULT: 15,
        FONT_SIZE_MEDIUM: 18,
        FONT_SIZE_LARGE: 24,
        FONT_SIZE_XLARGE: 27,
        FONT_SIZE_XXLARGE: 32,

        BOX_SHADOW: 3,
        BOX_BORDER_RADIUS: 6,

        COLOR_LAYOUT: {
            BACKGROUND_COLOR_ROOT: "#f0f2f5",
            COLOR_TEXT_ITEM_MENU: "#ffffff",
            COLOR_ITEM_MENU_ACTIVE: "#0f2c60",
            COLOR_ITEM_MENU_ACTIVE_PARENT: "#163978",
        }
    },

    /**
     * Regex Expression
     */
    RegExp: {
        /** https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript */
        EMAIL_ADDRESS: new RegExp(`/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@`
            + `((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/`),
        NEW_EMAIL_ADDRESS: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
        /** https://gist.github.com/HarishChaudhari/0dd5514ce430991a1b1b8fa04e8b72a4 */
        ID_CARD: new RegExp(/^[0-9]{8,12}$/),
        PHONE_NUMBER: new RegExp(/^0([135789]{1})([0-9]{8})$/),
        SPECIAL_CHARACTERS: new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/),
        PASSWORD: new RegExp(`/^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/`),
        WEBSITE: new RegExp(`/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;`)
    },

    /**
     * Storage keys
     */
    StorageKeys: {
        LANGUAGE: "LANGUAGE",
        CLIENT_ID: "CLIENT_ID",
        ORGANIZATION_ID: "ORGANIZATION_ID",
        FROM: "from",
        FCM_TOKEN: "FCM_TOKEN",
    },

    EventName: {
        NOT_FOUND: "NOT_FOUND",
        TOKEN_EXPIRED: "TOKEN_EXPIRED",
        NOT_PERMISSION: "NOT_PERMISSION",
        NOT_DATA_PERMISSION: "NOT_DATA_PERMISSION",
        LANGUAGE_CHANGE: "LANGUAGE_CHANGE",
        PROFILE_NOT_FOUND: "PROFILE_NOT_FOUND",
        CHOOSE_ORGANIZATION: "CHOOSE_ORGANIZATION",
    },

    /**
     * Cookie keys
     */
    CookieNames: {
        LANGUAGE: "lang",
        SESSION_ID: "sessionId"
    },

    /**
     * Row per paged
     */
    ROW_PER_PAGE: 20,
    ROW_PER_PAGE_5: 5,
    ROW_PER_PAGE_10: 10,
    ROW_PER_PAGE_20: 20,
    ROW_PER_PAGE_25: 25,
    ROW_PER_PAGE_50: 50,
    ROW_PER_PAGE_100: 100,
    ROW_PER_PAGE_OPTIONS: [20, 50, 100],
    ROW_PER_PAGE_OPTIONS_V1: [10, 25, 50],
    ROW_PER_PAGE_OPTIONS_TEST: [5, 10, 20],

    /**
    * format datetime
    */
    DateFormat: {
        DDMM: "DD/MM",
        MMYYYY: "MM/YYYY",
        DDMMYYYY: "DD/MM/YYYY",
        DDMMYYYY_HHMM: "DD/MM/YYYY HH:mm",
        DDMMYYYY_HHMMSS: "DD/MM/YYYY HH:mm:ss",
        HHMM_DDMMYYYY: "HH:mm DD/MM/YYYY",
        HHMM: "HH:mm",
        YYYY: "YYYY",
        YYYYMM: "YYYY/MM",
        YYYYMMDD: "YYYY/MM/DD",
        YYYYMMDD_HHMM: "YYYY/MM/DD HH:mm",
        DDDD: "dddd",
    },

    MomentWeekDay: {
        Sunday: 0,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
    },

    DefaultLanguage: "vi",
    LanguageContent: {
        VI: "vi",
        EN: "en",
    },
    COCCOC_BRAND_NAME: "CocCoc",

    /**
    * Resources
    */
    ResourceCode: {
        PROFILE: "identity/dashboard",
        DASHBOARD: "identity/dashboard",

        ROLE: "identity/role",
        POLICY: "identity/policy",

        STAFF: "identity/staff",
        GROUP: "identity/group",
        ORGANIZATION: "identity/organization",

        MANAGE_CLIENT: "identity/client",
        MANAGE_TENANT: "identity/tenant",
        MANAGE_SERVICE: "identity/service",

        EVENT: "identity/event",
        LOGIN_LOG: "identity/loginlog",
        NOTIFICATION: "circle/message",
        INVITATION: "identity/invitation",

        MENU: "identity/menu",
        MODULE: "identity/module",
        RESOURCE: "identity/resource",
        CODENAME: "identity/codename",
        SYSTEM_SETTING: "identity/systemsetting",
    },

    /**
    * Resources Menu
    */
    ResourceMenu: {
        DASHBOARD: "identity/dashboard",

        ROLE: "identity/role",
        POLICY: "identity/policy",

        STAFF: "identity/staff",
        GROUP: "identity/group",
        ORGANIZATION: "identity/organization",

        MANAGE_CLIENT: "identity/client",
        MANAGE_TENANT: "identity/tenant",
        MANAGE_SERVICE: "identity/service",

        EVENT: "identity/event",
        LOGIN_LOG: "identity/loginlog",
        INVITATION: "identity/invitation",
        NOTIFICATION: "identity/notification",

        MENU: "identity/menu",
        MODULE: "identity/module",
        RESOURCE: "identity/resource",
        CODENAME: "identity/codename",
        SYSTEM_SETTING: "identity/systemsetting",
    },

    // System Setting Code
    SERVICE_CODE: {
        ASSET: "000000",
        IDENTITY: "000001",
    },

    TENANT_CODE: {
        ASSET: "000000",
        IDENTITY: "000000",
    },

    CLIENT_ID: process.env.REACT_APP_DEFAULT_CLIENT_ID as string,

    SECRET_PASS: "C3K2khw@",

    ReduxSerializableCheck: {
        SET_TOKEN_INFO: "tokenInfo/setTokenInfo"
    },
};

export default Constants;
