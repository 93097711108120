import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Grid, IconButton, Tooltip } from "@mui/material";
import { CheckBoxOutlined, CropSquareOutlined } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Screens from "constants/screens";
import Strings from "constants/strings";
import Constants from "../../constants";
import PopupInputEditor from "./popupInputEditor";
import ServiceService from "services/identity/service.service";
import ResourceService from "services/identity/resource.service";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { commonGetAllDataService } from ".";
import { commonGetAllDataClient } from "screens/client";
import { setDataAlert } from "store/slice/message.slice";
import { showLoading } from "store/slice/loadingAPI.slice";
import { commonGetAllDataResource } from "screens/resource";
import { ICodename, IInput, IMultiLang, ITitleRoute } from "commons/interfaces";
import { AccessMode, ClientType, DefaultClient, Mode, ResourceType } from "constants/enum";
import {
    Button,
    DataTable,
    FormField,
    Box,
    Modal,
    Typography,
    Autocomplete,
    InputHtmlEditor,
    CustomIcon,
    ControlLabelCheckBox,
    SelectMultiLanguage,
    useCommonComponentContext,
    RoleType,
} from "@maysoft/common-component-react";

interface IModel {
    mode?: Mode;
    title?: string;
    route?: ITitleRoute[];
    language?: string;
}

interface IDataEdit {
    id?: string;
    endPoint?: IInput;
    serviceCode?: IInput;
    serviceName?: IInput;
    description?: IInput;

    userAgreement?: IMultiLang;
    termsOfService?: IMultiLang;
    userPolicy?: IMultiLang;
}

interface IServiceRelate {
    id: string;
    isUpdate?: boolean;
    relateCode?: IInput;
    relateModule?: IInput;
    listModule?: ICodename[];
}

interface IClientRequest {
    id?: string;
    isUpdate?: boolean;
    clientId?: IInput;
    clientType?: IInput;
    accessRoleGroup?: IInput;
    clientTypeName?: string;
    defaultClient?: number;
}

const serviceService = new ServiceService();
const resourceService = new ResourceService();

const ServiceEditScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const [dataEdit, setDataEdit] = useState<IDataEdit>({});
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [model, setModel] = useState<IModel>({} as IModel);
    const [listClient, setListClient] = useState<ICodename[]>([]);
    const [listService, setListService] = useState<ICodename[]>([]);
    const [listServiceRelate, setListServiceRelate] = useState<IServiceRelate[]>([]);
    const [listClientRequest, setListClientRequest] = useState<IClientRequest[]>([]);
    const [dataModal, setDataModal] = useState<{ open?: boolean; data?: IClientRequest }>({});
    const [dataModalSR, setDataModalSR] = useState<{ open?: boolean; data?: IServiceRelate }>({});
    const [listIdModule, setListIdModule] = useState<{ id?: string; relateModule?: string }[]>([]);

    const [dataPopupInputEditor, setDataPopupInputEditor] = useState<{
        open?: boolean;
        data?: { title: string; key: string; value?: any };
    }>({});

    const listMultiLanguage = useMemo(() => {
        return [
            { code: Constants.LanguageContent.VI, name: Strings.Languages.VI },
            { code: Constants.LanguageContent.EN, name: Strings.Languages.EN },
        ];
    }, [Strings.getLanguage()]);

    const listTypeClient: ICodename[] = [
        { code: ClientType.Admin, name: "Admin" },
        { code: ClientType.Web, name: "Web" },
        { code: ClientType.Mobile, name: "Mobile" },
        { code: ClientType.Checkout, name: "Checkout" },
        // { code: `${ClientType.APi}`, name: "API" },
    ];

    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);
    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.MANAGE_SERVICE);
    const language = Helpers.getItemInLocalStorage(Constants.StorageKeys.LANGUAGE, Constants.DefaultLanguage);

    useEffect(() => {
        getData();
    }, []);

    const handleGoBack = () => {
        const itemP = listPathName.find((el) => el.pathName === Screens.SERVICE_LIST);
        navigate(Screens.SERVICE_LIST + (itemP ? itemP.query : ""));
    };

    const id = searchParams.get("id");
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const getData = async () => {
        try {
            dispatch(showLoading(true));

            const listClientTemp: ICodename[] = await commonGetAllDataClient();
            let listServiceTemp: ICodename[] = await commonGetAllDataService();

            setListClient(listClientTemp);

            if (!Helpers.isNullOrEmpty(id)) {
                let mode = resourcePermissions.canUpdate ? pramsMode || Mode.Update : Mode.View;

                const result = await serviceService.getDetail(id);

                setDataEdit({
                    id: result.id,
                    serviceCode: { value: result.serviceCode },
                    serviceName: { value: result.serviceName },
                    description: { value: result.description },
                    endPoint: { value: result.endPoint },
                    userPolicy: result.userPolicy,
                    userAgreement: result.userAgreement,
                    termsOfService: result.termsOfService,
                });

                listServiceTemp = listServiceTemp.filter((el) => el.code !== result.serviceCode);
                setListService(listServiceTemp);

                let clientR: IClientRequest[] = [];
                (result.serviceClients || []).forEach((item: any) => {
                    clientR.push({
                        id: item.id,
                        isUpdate: true,
                        clientId: { value: item.clientId },
                        clientType: { value: `${item.clientType}` },
                        clientTypeName: listTypeClient.find((el) => `${el.code}` === `${item.clientType}`)?.name,
                        defaultClient: item.defaultClient || DefaultClient.None,
                        accessRoleGroup: { value: item?.accessRoleGroup },
                    });
                });

                setListClientRequest(clientR);

                const dataGroupByTemp: any = new Map();
                (result.serviceRelations || []).forEach((item: any) => {
                    const key = item.relateService;
                    let collect = dataGroupByTemp.get(key);
                    if (!collect) {
                        dataGroupByTemp.set(key, [item]);
                    } else {
                        collect.push(item);
                    }
                });

                let listIdModuleTemp: any[] = [];
                let serviceRelateR: IServiceRelate[] = [];
                for (const [key, value] of dataGroupByTemp) {
                    serviceRelateR.push({
                        id: value[0]?.id,
                        isUpdate: true,
                        relateCode: { value: key },
                        listModule: await getAllResourceByServiceCode(key),
                        relateModule: { value: (value || []).map((el: any) => el.relateModule) },
                    });
                    (value || []).forEach((el: any) => {
                        listIdModuleTemp.push({
                            id: el.id,
                            relateModule: el.relateModule,
                        });
                    });
                }

                setListIdModule(listIdModuleTemp);
                setListServiceRelate(serviceRelateR);
                setModel({
                    mode: mode,
                    language: language,
                    title: mode === Mode.View ? Strings.SERVICE.TITLE_DETAIL_VIEW : Strings.SERVICE.TITLE_UPDATE_VIEW,
                    route: [
                        {
                            title: Strings.SERVICE.TITLE_MENU,
                            route: Screens.SERVICE_LIST,
                        },
                        {
                            title: mode === Mode.View ? Strings.Common.VIEW : Strings.Common.UPDATE,
                            route: "",
                        },
                    ],
                });

                navigate(Screens.SERVICE_EDIT + `?id=${id}&mode=${mode}`, { replace: true });
            } else {
                setListService(listServiceTemp);
                setModel({
                    mode: Mode.Create,
                    language: language,
                    title: Strings.SERVICE.TITLE_CREATE_VIEW,
                    route: [
                        {
                            title: Strings.SERVICE.TITLE_MENU,
                            route: Screens.SERVICE_LIST,
                        },
                        {
                            title: Strings.Common.CREATE_NEW,
                            route: "",
                        },
                    ],
                });
            }

            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    };

    const onChangeValueBasic = (key: string, val: any, isMultiLanguage: boolean) => {
        let dataTemp: any = { ...dataEdit };
        if (isMultiLanguage) {
            dataTemp[key] = {
                ...dataTemp[key],
                value: {
                    ...dataTemp?.[key]?.value,
                    [model.language]: val,
                },
            };
        } else {
            dataTemp[key] = { value: val };
        }

        setDataEdit(dataTemp);
        setIsEdited(true);
    };

    const getAllResourceByServiceCode = async (serviceCode: string) => {
        let listModule: ICodename[] = await commonGetAllDataResource({
            isCode: true,
            serviceCode: serviceCode,
            resourceType: ResourceType.Module,
            accessMode: AccessMode.PrivateRBAC,
        });
        return listModule;
    };

    const checkValidate = () => {
        let checked: boolean = true;
        let data: any = { ...dataEdit };

        if (Helpers.isNullOrEmpty(data["serviceCode"]?.value)) {
            data["serviceCode"] = { error: Strings.Validation.REQUIRED };
            checked = false;
        }
        if (Helpers.isNullOrEmpty(data["serviceName"]?.value)) {
            data["serviceName"] = { error: Strings.Validation.REQUIRED };
            checked = false;
        }
        if (Helpers.isNullOrEmpty(data["endPoint"]?.value)) {
            data["endPoint"] = { error: Strings.Validation.REQUIRED };
            checked = false;
        }

        if (!Helpers.isNullOrEmpty(data["serviceCode"]?.error)) {
            checked = false;
        }
        if (!Helpers.isNullOrEmpty(data["serviceName"]?.error)) {
            checked = false;
        }
        if (!Helpers.isNullOrEmpty(data["endPoint"]?.error)) {
            checked = false;
        }

        if (!checked) {
            setDataEdit(data);
        }
        if (listClientRequest.length === 0) {
            checked = false;
            dispatch(setDataAlert({ message: Strings.SERVICE.NO_DATA_LIST_CLIENT, type: "error" }));
        }

        return checked;
    };

    const onSubmit = async () => {
        if (model.mode === Mode.View) {
            setModel({
                ...model,
                mode: Mode.Update,
                title: Strings.SERVICE.TITLE_UPDATE_VIEW,
                route: [
                    { title: Strings.SERVICE.TITLE_MENU, route: Screens.SERVICE_LIST },
                    { title: Strings.Common.UPDATE, route: "" },
                ],
            });
            navigate(Screens.SERVICE_EDIT + `?id=${id}&mode=${Mode.Update}`, { replace: true });
        } else {
            if (!checkValidate()) {
                return;
            } else {
                try {
                    dispatch(showLoading(true));
                    const clientRequests: any[] = [];
                    const serviceRelateRequests: any[] = [];

                    [...(listClientRequest || [])].forEach((item) => {
                        clientRequests.push({
                            id: item.isUpdate ? item.id : undefined,
                            clientId: item.clientId?.value,
                            clientType: Number(item?.clientType?.value),
                            defaultClient: item?.defaultClient,
                            accessRoleGroup: item?.accessRoleGroup?.value,
                        });
                    });

                    [...(listServiceRelate || [])].forEach((item) => {
                        (item.relateModule?.value || []).forEach((el: string) => {
                            const idTemp = listIdModule.find((i) => i.relateModule === el)?.id;
                            serviceRelateRequests.push({
                                id: item.isUpdate ? idTemp : undefined,
                                relateCode: item.relateCode?.value,
                                relateModule: el,
                            });
                        });
                    });

                    const dataSubmit = {
                        id: dataEdit.id,
                        serviceCode: dataEdit.serviceCode?.value,
                        serviceName: dataEdit.serviceName?.value,
                        description: dataEdit.description?.value,
                        endPoint: dataEdit.endPoint?.value,
                        userPolicy: Helpers.setValueMultiLanguage(dataEdit.userPolicy, model.language, listMultiLanguage) || {
                            value: { [model.language]: "" },
                        },
                        userAgreement: Helpers.setValueMultiLanguage(dataEdit.userAgreement, model.language, listMultiLanguage) || {
                            value: { [model.language]: "" },
                        },
                        termsOfService: Helpers.setValueMultiLanguage(dataEdit.termsOfService, model.language, listMultiLanguage) || {
                            value: { [model.language]: "" },
                        },
                        clientRequests,
                        serviceRelateRequests,
                    };

                    let result: any;
                    if (dataEdit.id) {
                        result = await serviceService.update(dataSubmit);
                    } else {
                        result = await serviceService.create(dataSubmit);
                    }

                    if (result.statusCode === Constants.ApiCode.SUCCESS) {
                        dispatch(
                            setDataAlert({
                                message: dataEdit?.id ? Strings.Message.UPDATE_SUCCESS : Strings.Message.CREATE_SUCCESS,
                                type: "success",
                            })
                        );
                    }

                    if (Helpers.isNullOrEmpty(dataEdit?.id)) {
                        handleGoBack();
                    } else {
                        setModel({
                            ...model,
                            mode: Mode.View,
                            title: Strings.SERVICE.TITLE_DETAIL_VIEW,
                            route: [
                                { title: Strings.SERVICE.TITLE_MENU, route: Screens.SERVICE_LIST },
                                { title: Strings.Common.VIEW, route: "" },
                            ],
                        });
                        navigate(Screens.SERVICE_EDIT + `?id=${id}&mode=${Mode.View}`, { replace: true });
                    }

                    dispatch(showLoading(false));
                } catch (error) {
                    dispatch(showLoading(false));
                    const e = Helpers.renderExceptionError(error);
                    dispatch(setDataAlert({ message: e, type: "error" }));
                }
            }
        }
    };

    // SERVICE RELATE
    const handleCheckValidateDataModalSR = () => {
        let isChecked: boolean = true;
        let dataTemp = { ...dataModalSR?.data };

        if (Helpers.isNullOrEmpty(dataTemp?.["relateCode"]?.value)) {
            dataTemp["relateCode"] = { error: Strings.Validation.REQUIRED };
            isChecked = false;
        }
        if (Helpers.isNullOrEmpty(dataTemp?.["relateModule"]?.value)) {
            dataTemp["relateModule"] = { error: Strings.Validation.REQUIRED };
            isChecked = false;
        }
        if (!Helpers.isNullOrEmpty(dataTemp?.["relateCode"]?.error)) {
            isChecked = false;
        }
        if (!Helpers.isNullOrEmpty(dataTemp?.["relateModule"]?.error)) {
            isChecked = false;
        }

        if (!isChecked) {
            setDataModalSR({ ...dataModalSR, data: dataTemp });
        }

        return isChecked;
    };

    const onConfirmModalServiceRelate = async () => {
        if (!handleCheckValidateDataModalSR()) {
            return;
        } else {
            const dataTemp = { ...dataModalSR?.data };
            let dataListTemp = [...(listServiceRelate || [])];

            if (Helpers.isNullOrEmpty(dataTemp?.id)) {
                // create
                dataListTemp.push({
                    id: Date.now().toString(),
                    listModule: dataTemp?.listModule,
                    relateCode: dataTemp?.relateCode,
                    relateModule: dataTemp?.relateModule,
                });
            } else {
                // update
                const index = dataListTemp.findIndex((el) => el.id === dataTemp?.id);
                if (index !== -1) {
                    dataListTemp[index] = {
                        ...dataListTemp[index],
                        id: dataTemp?.id,
                        listModule: dataTemp?.listModule,
                        relateCode: dataTemp?.relateCode,
                        relateModule: dataTemp?.relateModule,
                    };
                }
            }

            setIsEdited(true);
            setDataModalSR({});
            setListServiceRelate(dataListTemp);
        }
    };

    const renderModalServiceRelate = () => (
        <Modal
            fullWidth
            maxWidth="md"
            hasActionButton
            key="modalServiceRelate"
            visible={dataModalSR?.open || false}
            onClose={() => setDataModalSR({})}
            onAction={() => onConfirmModalServiceRelate()}
            title={dataModalSR?.data?.id ? Strings.Common.UPDATE : Strings.Common.ADD_NEW}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        required
                        mode={model.mode}
                        data={listService || []}
                        label={Strings.SERVICE.NAME}
                        placeholder={Strings.SERVICE.SELECT_SERVICE}
                        defaultValue={dataModalSR?.data?.relateCode?.value}
                        errorMessage={dataModalSR?.data?.relateCode?.error}
                        onChange={async (value) => {
                            dispatch(showLoading(true));

                            const dataListTemp = [...(listServiceRelate || [])];
                            let listModule: ICodename[] = [...(dataModalSR?.data?.listModule || [])];

                            let err: string = "";
                            if (dataListTemp.findIndex((el) => el.relateCode?.value === value) !== -1) {
                                const nameService = listService.find((el) => el.code === value)?.name;
                                err = Strings.formatString(Strings.SERVICE.SERVICE_ALREADY_EXISTS, nameService).toString();
                            } else {
                                listModule = await getAllResourceByServiceCode(value);
                            }

                            setDataModalSR({
                                ...dataModalSR,
                                data: {
                                    ...dataModalSR?.data,
                                    listModule: listModule,
                                    relateModule: { value: undefined },
                                    relateCode: { value, error: err },
                                },
                            });

                            dispatch(showLoading(false));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        required
                        multiple
                        isGroupBy
                        mode={model.mode}
                        label={Strings.SERVICE.NAME_MODULE}
                        data={dataModalSR?.data?.listModule || []}
                        defaultValue={dataModalSR?.data?.relateModule?.value}
                        errorMessage={dataModalSR?.data?.relateModule?.error}
                        placeholder={Strings.SERVICE.SELECT_NAME_MODULE}
                        onChange={(value) => {
                            setDataModalSR({
                                ...dataModalSR,
                                data: {
                                    ...dataModalSR?.data,
                                    relateModule: { value },
                                },
                            });
                        }}
                    />
                </Grid>
            </Grid>
        </Modal>
    );

    // CLIENT

    const accessRoleList: ICodename[] = [
        { code: RoleType.Service, name: Strings.ACCESS_ROLE.SERVICE },
        { code: RoleType.TenantOrMembership, name: Strings.ACCESS_ROLE.TENANT_OR_MEMBERSHIP },
        { code: RoleType.Normal, name: Strings.ACCESS_ROLE.NORMAL },
        { code: RoleType.User, name: Strings.ACCESS_ROLE.USER },
    ];

    const onValueChangeByDataModalClient = async (key: string, value: any, isMultiLanguage?: boolean) => {
        let data: any = { ...dataModal?.data };
        if (isMultiLanguage === true) {
            data[key] = {
                ...data[key],
                error: undefined,
                value: { [model.language]: value },
            };
        } else {
            if (key === "defaultClient") {
                data[key] = value === true ? DefaultClient.Default : DefaultClient.None;
            } else if (key === "clientId") {
                const index = listClientRequest.findIndex((el) => el.clientId?.value === value && data["id"] !== el.id);
                if (index !== -1) {
                    const err = Strings.formatString(Strings.SERVICE.CLIENTID_ALREADY_EXISTS, value);
                    data[key] = { value, error: err.toString() };
                } else {
                    data[key] = { value };
                }
            } else {
                data[key] = { value };
            }
        }
        setDataModal({ ...dataModal, data });
    };

    const handleOnActionByModalClient = () => {
        let isChecked: boolean = true;
        let dataTemp: any = { ...dataModal.data };
        const listKey: string[] = ["clientId", "clientType", "accessRoleGroup"];
        listKey.forEach((key) => {
            if (Helpers.isNullOrEmpty(dataTemp[key]?.value) || dataTemp[key]?.value === 0) {
                dataTemp[key] = { error: Strings.Validation.REQUIRED };
                isChecked = false;
            }
            if (!Helpers.isNullOrEmpty(dataTemp[key]?.error)) {
                isChecked = false;
            }
        });

        if (!isChecked) {
            setDataModal({ ...dataModal, data: dataTemp });
        } else {
            let listClientRequestTemp = [...(listClientRequest || [])];
            if (Helpers.isNullOrEmpty(dataTemp?.id)) {
                // add new
                listClientRequestTemp.push({
                    ...dataTemp,
                    id: Date.now().toString(),
                    clientTypeName: listTypeClient.find((el) => el.code === dataTemp?.clientType?.value)?.name,
                });
            } else {
                // update
                const index = listClientRequestTemp.findIndex((el) => el.id === dataTemp?.id);
                if (index !== -1) {
                    listClientRequestTemp[index] = {
                        ...dataTemp,
                        clientTypeName: listTypeClient.find((el) => el.code === dataTemp?.clientType?.value)?.name,
                    };
                }
            }
            setListClientRequest(listClientRequestTemp);
            setDataModal({});
        }
    };

    const renderModalClient = () =>  (
            <Modal
                fullWidth
                maxWidth="sm"
                hasActionButton
                visible={dataModal?.open || false}
                onClose={() => setDataModal({})}
                onAction={() => handleOnActionByModalClient()}
                title={dataModal?.data?.id ? Strings.Common.UPDATE : Strings.Common.ADD_NEW}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ControlLabelCheckBox
                            label={Strings.SERVICE.DEFAULT_CLIENT}
                            value={dataModal?.data?.defaultClient === DefaultClient.Default}
                            onChangeValue={(value) => {
                                if (value === true) {
                                    const index = listClientRequest.findIndex(
                                        (el) => el.defaultClient === DefaultClient.Default && dataModal?.data?.id !== el.id
                                    );
                                    if (index === -1) {
                                        onValueChangeByDataModalClient("defaultClient", value);
                                    } else {
                                        dispatch(setDataAlert({ message: Strings.SERVICE.DEFAULT_CLIENT_ALREADY_EXISTS, type: "warning" }));
                                    }
                                } else {
                                    onValueChangeByDataModalClient("defaultClient", value);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            required
                            data={listClient || []}
                            label={Strings.SERVICE.CLIENT}
                            placeholder={Strings.SERVICE.SELECT_CLIENT}
                            defaultValue={dataModal?.data?.clientId?.value}
                            errorMessage={dataModal?.data?.clientId?.error}
                            onChange={(value) => onValueChangeByDataModalClient("clientId", value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete
                            required
                            data={listTypeClient || []}
                            label={Strings.SERVICE.TYPE_CLIENT}
                            placeholder={Strings.SERVICE.SELECT_TYPE_CLIENT}
                            defaultValue={dataModal?.data?.clientType?.value}
                            errorMessage={dataModal?.data?.clientType?.error}
                            onChange={(value) => onValueChangeByDataModalClient("clientType", value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            required
                            multiple
                            data={accessRoleList || []}
                            label={Strings.SERVICE.ACCESS_ROLE_GROUP}
                            placeholder={Strings.SERVICE.SELECT_ACCESS_ROLE_GROUP}
                            defaultValue={accessRoleList
                                .filter((el) => (Number(el.code) & dataModal?.data?.accessRoleGroup?.value) === Number(el.code))
                                .map((el) => el.code)}
                            errorMessage={dataModal?.data?.accessRoleGroup?.error}
                            onChange={(value: number[]) =>
                                onValueChangeByDataModalClient(
                                    "accessRoleGroup",
                                    value.length === 0 ? 0 : value.reduce((acc, cur) => (acc = acc | cur), 0)
                                )
                            }
                        />
                    </Grid>
                </Grid>
            </Modal>
        );
  

    const renderListClient = () => (
        <Card>
            <Box p={2}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5">{Strings.CLIENT.TITLE_MENU}</Typography>
                    {model.mode !== Mode.View && (
                        <Button
                            sx={{ mr: 2 }}
                            variant="outlined"
                            color="info"
                            onClick={() => {
                                setDataModal({ open: true, data: {} });
                            }}
                        >
                            {Strings.Common.ADD_NEW}
                        </Button>
                    )}
                </Box>
                <Box p={2}>
                    <DataTable
                        isLocal
                        isShowIndex
                        loading={false}
                        pageNumber={1}
                        rowPerPage={Constants.ROW_PER_PAGE}
                        hideActionMenu={model.mode === Mode.View}
                        totalCount={(listClientRequest || []).length}
                        table={{
                            columns: [
                                {
                                    Header: Strings.SERVICE.DEFAULT_CLIENT,
                                    accessor: "defaultClient",
                                    width: "70px",
                                    Cell: ({ value }: any) =>
                                        Number(value) === DefaultClient.Default ? <CheckBoxOutlined /> : <CropSquareOutlined />,
                                },
                                {
                                    Header: Strings.SERVICE.CLIENT,
                                    accessor: "clientId",
                                    Cell: ({ value }: any) => <>{value?.value}</>,
                                },
                                { Header: Strings.SERVICE.TYPE_CLIENT, accessor: "clientTypeName" },
                            ],
                            rows: listClientRequest || [],
                        }}
                        actionList={(row) => [
                            {
                                key: "edit",
                                iconName: "edit",
                                title: Strings.Common.EDIT,
                                callback: (row) => setDataModal({ open: true, data: row }),
                            },
                            {
                                key: "delete",
                                iconName: "delete",
                                title: Strings.Common.DELETE,
                                callback: (row) => {
                                    let listClientRequestTemp = [...(listClientRequest || [])].filter((el) => el.id !== row.id);
                                    setListClientRequest(listClientRequestTemp);
                                },
                            },
                        ]}
                    />
                </Box>
                {renderModalClient()}
            </Box>
        </Card>
    );

    const handleInitRole = (id: string) => {
        const content = Strings.formatString(Strings.SERVICE.CONFIRM_INIT_ROLE, `${dataEdit?.serviceName?.value}`).toString();

        Helpers.showConfirmAlert(content, async () => {
            try {
                dispatch(showLoading(true));

                await serviceService.initRole(id);
                const message = Strings.formatString(Strings.SERVICE.INIT_ROLE_SUCCESS, dataEdit?.serviceName?.value).toString();

                dispatch(setDataAlert({ message: message, type: "success" }));

                dispatch(showLoading(false));
            } catch (error: any) {
                dispatch(showLoading(false));
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            }
        });
    };

    const handleInitDataResource = (code: string) => {
        const content = Strings.formatString(Strings.SERVICE.CONFIRM_INIT_DATA_RESOURCE, `${dataEdit?.serviceName?.value}`).toString();

        Helpers.showConfirmAlert(content, async () => {
            try {
                dispatch(showLoading(true));

                await resourceService.initData(code);

                const message = Strings.formatString(Strings.SERVICE.INIT_DATA_RESOURCE_SUCCESS, dataEdit?.serviceName?.value).toString();

                dispatch(setDataAlert({ message: message, type: "success" }));

                dispatch(showLoading(false));
            } catch (error: any) {
                dispatch(showLoading(false));
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            }
        });
    };

    const renderInfoMore = () => (
        <Card>
            <Box p={2}>
                <Box mb={1} display="flex" alignItems="center">
                    <Typography variant="h6" fontWeight="bold">
                        {Strings.SERVICE.TERMS_OF_SERVICE}
                    </Typography>
                    <Tooltip key={"terms"} title={model.mode === Mode.View ? Strings.Common.VIEW : Strings.Common.EDIT}>
                        <IconButton
                            onClick={() => {
                                setDataPopupInputEditor({
                                    open: true,
                                    data: {
                                        key: "termsOfService",
                                        title: Strings.SERVICE.TERMS_OF_SERVICE,
                                        value: dataEdit.termsOfService?.value?.[model.language] || "",
                                    },
                                });
                            }}
                        >
                            <CustomIcon iconName={model.mode === Mode.View ? "view" : "edit"} />
                        </IconButton>
                    </Tooltip>
                </Box>
                <InputHtmlEditor
                    readonly
                    hideToolbar
                    disable={model.mode === Mode.View}
                    value={dataEdit.termsOfService?.value?.[model.language] || ""}
                    maxHeight={Helpers.isNullOrEmpty(dataEdit.termsOfService?.value?.[model.language]) ? "50px" : "200px"}
                />
            </Box>

            <Box p={2}>
                <Box mb={1} display="flex" alignItems="center">
                    <Typography variant="h6" fontWeight="bold">
                        {Strings.SERVICE.USER_AGREEMENT}
                    </Typography>
                    <Tooltip key={"user_agreement"} title={model.mode === Mode.View ? Strings.Common.VIEW : Strings.Common.EDIT}>
                        <IconButton
                            onClick={() => {
                                setDataPopupInputEditor({
                                    open: true,
                                    data: {
                                        key: "userAgreement",
                                        title: Strings.SERVICE.USER_AGREEMENT,
                                        value: dataEdit.userAgreement?.value?.[model.language] || "",
                                    },
                                });
                            }}
                        >
                            <CustomIcon iconName={model.mode === Mode.View ? "view" : "edit"} />
                        </IconButton>
                    </Tooltip>
                </Box>
                <InputHtmlEditor
                    readonly
                    hideToolbar
                    disable={model.mode === Mode.View}
                    value={dataEdit.userAgreement?.value?.[model.language] || ""}
                    maxHeight={Helpers.isNullOrEmpty(dataEdit.userAgreement?.value?.[model.language]) ? "50px" : "200px"}
                />
            </Box>

            <Box p={2}>
                <Box mb={1} display="flex" alignItems="center">
                    <Typography variant="h6" fontWeight="bold">
                        {Strings.SERVICE.USER_POLICY}
                    </Typography>
                    <Tooltip key={"user_policy"} title={model.mode === Mode.View ? Strings.Common.VIEW : Strings.Common.EDIT}>
                        <IconButton
                            onClick={() => {
                                setDataPopupInputEditor({
                                    open: true,
                                    data: {
                                        key: "userPolicy",
                                        title: Strings.SERVICE.USER_POLICY,
                                        value: dataEdit.userPolicy?.value?.[model.language] || "",
                                    },
                                });
                            }}
                        >
                            <CustomIcon iconName={model.mode === Mode.View ? "view" : "edit"} />
                        </IconButton>
                    </Tooltip>
                </Box>
                <InputHtmlEditor
                    readonly
                    hideToolbar
                    disable={model.mode === Mode.View}
                    value={dataEdit.userPolicy?.value?.[model.language] || ""}
                    maxHeight={Helpers.isNullOrEmpty(dataEdit.userPolicy?.value?.[model.language]) ? "50px" : "200px"}
                />
            </Box>
        </Card>
    );

    const renderBasicInfo = () => (
        <Card>
            <Box p={2}>
                <Box alignItems="center" justifyContent="space-between" sx={{ display: "flex", flexWrap: "wrap" }}>
                    <Typography variant="h5">{Strings.SERVICE.BASIC_INFOR}</Typography>
                    {model?.mode !== Mode.View && (
                        <SelectMultiLanguage
                            defaultLanguage={model.language}
                            onChangeLanguage={(value) => {
                                setModel({ ...model, language: value });
                            }}
                        />
                    )}
                </Box>
                <Grid container spacing={3} p={2}>
                    {!Helpers.isNullOrEmpty(dataEdit.id) && model.mode !== Mode.View && (
                        <Grid item xs={12} display="flex" alignItems="center">
                            <Button
                                color="error"
                                variant="outlined"
                                onClick={() => {
                                    handleInitRole(dataEdit.id);
                                }}
                            >
                                {Strings.SERVICE.INIT_ROLE}
                            </Button>
                            <Box ml={1}>
                                <Button
                                    color="success"
                                    variant="outlined"
                                    onClick={() => {
                                        handleInitDataResource(dataEdit.serviceCode?.value);
                                    }}
                                >
                                    {Strings.SERVICE.INIT_DATA_RESOURCE}
                                </Button>
                            </Box>
                        </Grid>
                    )}

                    <Grid item xs={12} md={6}>
                        <FormField
                            required
                            maxLength={255}
                            mode={model.mode}
                            label={Strings.SERVICE.CODE}
                            placeholder={Strings.SERVICE.ENTER_CODE}
                            value={dataEdit.serviceCode?.value || ""}
                            errorMessage={dataEdit.serviceCode?.error || ""}
                            disabled={!Helpers.isNullOrEmpty(dataEdit.id)}
                            onChangeValue={(value: any) => {
                                onChangeValueBasic("serviceCode", value, false);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormField
                            required
                            maxLength={255}
                            mode={model.mode}
                            label={Strings.SERVICE.NAME}
                            placeholder={Strings.SERVICE.ENTER_NAME}
                            value={dataEdit.serviceName?.value || ""}
                            errorMessage={dataEdit.serviceName?.error || ""}
                            onChangeValue={(value: any) => {
                                onChangeValueBasic("serviceName", value, false);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <FormField
                            required
                            isFullRow
                            maxLength={255}
                            mode={model.mode}
                            label={Strings.SERVICE.LINK_API}
                            placeholder={Strings.SERVICE.ENTER_LINK_API}
                            value={dataEdit.endPoint?.value || ""}
                            errorMessage={dataEdit.endPoint?.error || ""}
                            onChangeValue={(value: any) => {
                                onChangeValueBasic("endPoint", value, false);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormField
                            isFullRow
                            multiline
                            maxLength={500}
                            mode={model.mode}
                            label={Strings.SERVICE.DESCRIPTION}
                            value={dataEdit.description?.value || ""}
                            errorMessage={dataEdit.description?.error || ""}
                            placeholder={Strings.SERVICE.ENTER_DESCRIPTION}
                            onChangeValue={(value: any) => {
                                onChangeValueBasic("description", value, false);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {renderModalServiceRelate()}
                        <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="button" fontWeight="bold">
                                {Strings.SERVICE.SERVICE_RELATE_REQUESTS}
                            </Typography>
                            {model.mode !== Mode.View && (
                                <Button
                                    variant="outlined"
                                    color="info"
                                    onClick={() => {
                                        setDataModalSR({ open: true });
                                    }}
                                >
                                    {Strings.Common.ADD_NEW}
                                </Button>
                            )}
                        </Box>
                        <DataTable
                            isLocal
                            isShowIndex
                            loading={false}
                            pageNumber={1}
                            rowPerPage={Constants.ROW_PER_PAGE}
                            totalCount={listServiceRelate.length}
                            hideActionMenu={model.mode === Mode.View}
                            table={{
                                columns: [
                                    {
                                        Header: Strings.SERVICE.NAME,
                                        accessor: "relateCode",
                                        width: 100,
                                        Cell: ({ value }: any) => <>{listService.find((el) => el.code === value?.value)?.name}</>,
                                    },
                                    {
                                        Header: Strings.SERVICE.NAME_MODULE,
                                        accessor: "relateModule",
                                        width: 400,
                                        Cell: (row: any) => (
                                            <Box gap={0.5} display="grid">
                                                {[...(row?.value?.value || [])].map((code) => {
                                                    const name = [...(row.row?.original?.listModule || [])].find(
                                                        (el: ICodename) => el.code === code
                                                    )?.name;
                                                    return (
                                                        <Typography variant="caption" color="secondary" fontWeight="bold">
                                                            {`- ${name}`}
                                                        </Typography>
                                                    );
                                                })}
                                            </Box>
                                        ),
                                    },
                                ],
                                rows: listServiceRelate || [],
                            }}
                            actionList={(row) => [
                                {
                                    key: "edit",
                                    iconName: "edit",
                                    title: Strings.Common.EDIT,
                                    callback: (row) => setDataModalSR({ open: true, data: row }),
                                },
                                {
                                    key: "delete",
                                    iconName: "delete",
                                    title: Strings.Common.DELETE,
                                    callback: (row) => {
                                        let listServiceRelateTemp = [...(listServiceRelate || [])].filter((el) => el.id !== row.id);
                                        setListServiceRelate(listServiceRelateTemp);
                                    },
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );

    return (
        <DashboardLayout
            title={model.title}
            route={model.route}
            isPermission={resourcePermissions.canRead}
            nameActionPress={model.mode === Mode.View ? Strings.Common.EDIT : Strings.Common.SAVE}
            onActionPress={resourcePermissions.canUpdate || resourcePermissions.canCreate ? onSubmit : undefined}
            onBackPress={() => {
                if (!isEdited) {
                    handleGoBack();
                } else {
                    Helpers.showConfirmAlert(Strings.Message.CANCEL_ACTION_MESSAGE, handleGoBack);
                }
            }}
        >
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {renderBasicInfo()}
                    </Grid>
                    <Grid item xs={12}>
                        {renderListClient()}
                    </Grid>
                    <Grid item xs={12}>
                        {renderInfoMore()}
                    </Grid>
                </Grid>
            </Box>
            {dataPopupInputEditor.open && (
                <PopupInputEditor
                    required={true}
                    disable={model.mode === Mode.View}
                    title={dataPopupInputEditor.data?.title}
                    value={dataPopupInputEditor.data?.value}
                    open={dataPopupInputEditor.open || false}
                    onClose={() => {
                        setDataPopupInputEditor({});
                    }}
                    onAction={(value) => {
                        onChangeValueBasic(dataPopupInputEditor.data?.key, value, true);
                    }}
                />
            )}
        </DashboardLayout>
    );
};

export default ServiceEditScreen;
