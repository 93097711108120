import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Helpers from "commons/helpers";
import Constants from '../../constants';
import Screens from "constants/screens";
import Strings from "constants/strings";
import ResourceService from 'services/identity/resource.service';

import { RootState } from 'store';
import { DashboardLayout } from "layout";
import { ICodename } from "commons/interfaces";
import { setDataAlert } from 'store/slice/message.slice';
import { commonGetAllDataService } from "screens/service";
import { showLoading } from "store/slice/loadingAPI.slice";
import { setListPathName } from 'store/slice/titleRoute.slice';
import { ResourceType, Mode, ActionTypeSelectBox } from "constants/enum";
import { Box, DataTable, FormField, Chip, Autocomplete, useCommonComponentContext } from "@maysoft/common-component-react";

interface IRequestGetPaged {
    serviceCode?: string
    clientId?: string
    searchText?: string
    resourceType?: number
    totalCount?: number
    pageNumber?: number
    pageSize?: number
    orderby?: string
}
interface IModel {
    dataRequest?: IRequestGetPaged;
    dataRequestTemp?: IRequestGetPaged;
}

const resourceService = new ResourceService();

export const commonGetAllDataResource = async (params: {
    isCode: boolean,
    serviceCode: string,
    resourceType: number,
    organizationId?: string,
    parentResource?: string,
    accessMode?: number,
    statuses?: number[],
}) => {
    try {
        let listTemp: ICodename[] = [];

        const result = await resourceService.getAll(params);
        [...result || []].forEach((e: any) => {
            listTemp.push({
                group: e.serviceCode,
                code: params.isCode ? e.resourceCode : e.id,
                name: Helpers.renderValueByLanguage(e.resourceName?.value),
            })
        })

        return listTemp;
    } catch (error) { return []; }
}

const ResourceScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { getResourcePermissions } = useCommonComponentContext();

    const [dataTable, setDataTable] = useState<any[]>([]);
    const [model, setModel] = useState<IModel>({} as IModel);
    const [dataSelected, setDataSelected] = useState<string[]>([]);
    const [dataServices, setDataServices] = useState<ICodename[]>([]);

    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.RESOURCE);
    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);
    const clientId = Helpers.getUrlParams(["clientId"])?.clientId || Helpers.getItemInLocalStorage(Constants.StorageKeys.CLIENT_ID);

    useEffect(() => { onStart() }, []);

    const onStart = async () => {
        try {
            dispatch(showLoading(true));

            const { pageSize } = Helpers.getUrlParams(["pageSize"]);
            const { pageNumber } = Helpers.getUrlParams(["pageNumber"]);
            const { searchText } = Helpers.getUrlParams(["searchText"]);
            const { serviceCode } = Helpers.getUrlParams(["serviceCode"]);

            const itemPathName = listPathName.find(el => el.pathName === Screens.RESOURCE_LIST);

            let data: any = {
                clientId: clientId,
                searchText: searchText,
                serviceCode: serviceCode,
                pageNumber: Number(pageNumber || 1),
                pageSize: Number(pageSize || Constants.ROW_PER_PAGE),
                totalCount: itemPathName?.totalCount,
            }

            if (!Helpers.isNullOrEmpty(clientId)) {
                Helpers.setItemInLocalStorage(Constants.StorageKeys.CLIENT_ID, clientId)
            }

            const resultDataService = await commonGetAllDataService();
            setDataServices(resultDataService);

            await getPaged(data);

            dispatch(showLoading(false))
        } catch (error: any) {
            dispatch(showLoading(false))
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    }

    const getPaged = async (data?: IRequestGetPaged) => {
        try {
            dispatch(showLoading(true));

            const pageSize = data?.pageSize || Constants.ROW_PER_PAGE;
            const pageNumber = Helpers.getPageNumber(data?.pageNumber || 1, pageSize, data?.totalCount || 0);
            const request: any = {
                serviceCode: data?.serviceCode || Constants.SERVICE_CODE.IDENTITY,
                clientId: data?.clientId || Constants.CLIENT_ID,
                resourceType: ResourceType.Controller,
                searchText: data?.searchText,
                pageNumber: pageNumber || 1,
                pageSize: pageSize,
            };

            const result = await resourceService.getPaged(request);

            let query: string = `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
            if (!Helpers.isNullOrEmpty(data?.searchText)) {
                query += `&searchText=${data?.searchText}`;
            }
            if (!Helpers.isNullOrEmpty(data?.serviceCode)) {
                query += `&serviceCode=${data?.serviceCode}`
            }

            setDataTable(result.items || []);

            setModel({
                dataRequest: { ...request, totalCount: result.totalCount },
                dataRequestTemp: { ...request, totalCount: result.totalCount },
            });

            navigate(Screens.RESOURCE_LIST + query, { replace: true });

            dispatch(setListPathName({ pathname: Screens.RESOURCE_LIST, query, totalCount: result.totalCount }));
            dispatch(showLoading(false))
        } catch (error: any) {
            dispatch(showLoading(false))
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    }

    const handleDelete = (data: { deleteAll: false, ids: string[], id: string }) => {
        Helpers.showConfirmAlert(Strings.Message.CONFIRM_DELETE, async () => {
            try {
                dispatch(showLoading(true));

                const result = await resourceService.deleteById(data.deleteAll ? data.ids : [data.id]);

                if (result.statusCode === Constants.ApiCode.SUCCESS) {
                    await getPaged(model.dataRequest);

                    if (!data.deleteAll) {
                        const listTemp = [...dataSelected || []].filter(el => el !== data.id);
                        setDataSelected(listTemp);
                    }

                    dispatch(setDataAlert({ message: Strings.Message.DELETE_SUCCESS, type: "success" }));
                }

                dispatch(showLoading(false));
            } catch (error) {
                dispatch(showLoading(false));
                const e = Helpers.renderExceptionError(error);
                dispatch(setDataAlert({ message: e, type: "error" }));
            }
        });
    }

    const onValueChange = (newVal: any, key: string) => {
        let dataTemp: any = { ...model.dataRequestTemp };
        dataTemp[key] = newVal;
        setModel({ ...model, dataRequestTemp: dataTemp });
    }

    const filterForm = () => {
        return (
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormField
                            maxLength={255}
                            label={Strings.RESOURCE.SEARCH_TEXT}
                            placeholder={Strings.RESOURCE.SEARCH_TEXT}
                            defaultValue={model.dataRequestTemp?.searchText}
                            onBlur={(value) => onValueChange(value, "searchText")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            isSelectedBox
                            data={dataServices || []}
                            label={Strings.SERVICE.NAME}
                            defaultValue={model.dataRequestTemp?.serviceCode}
                            onChange={(value) => {
                                onValueChange(value, "serviceCode")
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    };

    return (
        <DashboardLayout
            isPermission={resourcePermissions.canRead}
            title={Strings.RESOURCE.TITLE_LIST_VIEW}
            route={[{ title: Strings.RESOURCE.TITLE_MENU, route: "" }]}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box mb={1}>
                        {!Helpers.isNullOrEmpty(model.dataRequest?.serviceCode) && (
                            <Chip
                                label={Strings.SERVICE.NAME}
                                value={dataServices.find(el => el.code === model.dataRequest?.serviceCode)?.name}
                                onDelete={(Constants.SERVICE_CODE.IDENTITY === model.dataRequest?.serviceCode)
                                    ? undefined
                                    : () => {
                                        getPaged({ ...model.dataRequest, serviceCode: Constants.SERVICE_CODE.IDENTITY })
                                    }}
                            />
                        )}
                    </Box>
                    <Card>
                        <Box p={2}>
                            <DataTable
                                allowCheckbox
                                loading={false}
                                dataSelected={dataSelected}
                                handleOnChangeSelected={(data) => setDataSelected(data)}

                                rowPerPage={model.dataRequest?.pageSize}
                                totalCount={model.dataRequest?.totalCount}
                                pageNumber={model.dataRequest?.pageNumber}
                                onChangePageSize={(pageSize) => getPaged({ ...model.dataRequest, pageSize })}
                                onChangePageNumber={(pageNumber) => getPaged({ ...model.dataRequest, pageNumber })}

                                searchText={model.dataRequest?.searchText}
                                placeholderSearch={Strings.RESOURCE.SEARCH_TEXT}
                                onSearch={(val) => getPaged({ ...model.dataRequest, pageNumber: 1, searchText: val })}
                                onSearchChange={(val) => {
                                    setModel({ ...model, dataRequest: { ...model.dataRequest, searchText: val } })
                                }}

                                filterForm={filterForm()}
                                onFilter={() => { getPaged({ ...model.dataRequestTemp, pageNumber: 1 }) }}
                                onReset={() => {
                                    getPaged({
                                        ...model.dataRequest, pageNumber: 1, searchText: "",
                                        serviceCode: Constants.SERVICE_CODE.IDENTITY
                                    })
                                }}
                                onCloseFilter={() => {
                                    const dataTemp: any = { ...model.dataRequest };
                                    setModel({ ...model, dataRequestTemp: dataTemp });
                                }}

                                actionTypeSelectBox={[
                                    { actionType: ActionTypeSelectBox.Delete, actionName: Strings.Common.DELETE, color: "error" }
                                ]}
                                onSubmitActionCheckedBox={(data, actionType) => {
                                    if (actionType === ActionTypeSelectBox.Delete) {
                                        handleDelete({ deleteAll: false, ids: data, id: "" })
                                    }
                                }}

                                table={{
                                    rows: dataTable,
                                    columns: [
                                        {
                                            Header: Strings.RESOURCE.CODE, accessor: "resourceCode",
                                            Cell: (row: any) => (
                                                <Link
                                                    className="hyperlink"
                                                    to={Screens.RESOURCE_EDIT + `?id=${row.row?.original?.id}&mode=${Mode.View}`}
                                                >
                                                    {row.value}
                                                </Link>
                                            )
                                        },
                                        {
                                            Header: Strings.RESOURCE.NAME, accessor: "resourceName",
                                            Cell: (row: any) => <>{Helpers.renderValueByLanguage(row?.value?.value)}</>
                                        },
                                    ]
                                }}

                                actionList={row => [
                                    {
                                        key: "detail",
                                        iconName: "view",
                                        title: Strings.Common.VIEW_ALL,
                                        callback: (row) => navigate(Screens.RESOURCE_EDIT + `?id=${row.id}&mode=${Mode.View}`),
                                    },
                                    resourcePermissions.canUpdate && {
                                        key: "edit",
                                        iconName: "edit",
                                        title: Strings.Common.EDIT,
                                        callback: (row) => navigate(Screens.RESOURCE_EDIT + `?id=${row.id}&mode=${Mode.Update}`),
                                    },
                                    resourcePermissions.canDelete && {
                                        key: "delete",
                                        iconName: "delete",
                                        title: Strings.Common.DELETE,
                                        callback: (row) => handleDelete({ deleteAll: false, ids: [], id: row.id }),
                                    },
                                ]}
                            />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}

export default ResourceScreen;