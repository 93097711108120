import { IItemRoute } from "@maysoft/common-component-react";
import {
    AddHomeWork,
    AssuredWorkload,
    Category,
    Dashboard,
    Diversity1,
    ExitToApp,
    Groups,
    LockPerson,
    Mail,
    Settings,
} from "@mui/icons-material";
import { useEffect, useState } from "react";

import Constants from "constants/index";
import Screens from "constants/screens";
import Strings from "constants/strings";

import DasboardScreen from 'screens/dashboard';
import ProfileScreen from 'screens/profile';

import GroupScreen from 'screens/group';
import OrganizationScreen from 'screens/organization';
import RoleScreen from 'screens/role';
import StaffScreen from 'screens/staff';

import ModuleScreen from 'screens/module';

import ClientScreen from "screens/client";
import ClientEditScreen from "screens/client/edit";

import MenuScreen from "screens/menu";
import MenuEditScreen from "screens/menu/edit";

import ServiceScreen from "screens/service";
import ServiceEditScreen from "screens/service/edit";

import TenantScreen from "screens/tenant";
import TenantEditScreen from "screens/tenant/edit";

import CodenameScreen from "screens/codename";

import ResourceScreen from "screens/resource";
import ResourceEditScreen from "screens/resource/edit";

import InvitationScreen from "screens/invitation";
import LoginLogScreen from "screens/login/loginLog";


import NotificationScreen from "screens/notification";

import EventScreen from "screens/events";
import EventDetailScreen from "screens/events/detail";

import SystemSettingScreen from "screens/systemsetting";
import SystemSettingEditScreen from "screens/systemsetting/edit";



export const useRenderRoute = (key: any) => {
    const [routes, setRoutes] = useState<IItemRoute[]>([]);

    useEffect(() => {
        const mainRoutes: IItemRoute[] = [
            // Dashboard
            {
                key: "dashboard",
                isVisible: true,
                icon: <Dashboard />,
                iconName: "dashboard",
                screenPath: Screens.DASHBOARD,
                screenName: <DasboardScreen />,
                title: Strings.DASHBOARD.TITLE_MENU,
                resourceCode: Constants.ResourceMenu.DASHBOARD,
            },

            // Profile
            {
                key: "profile",
                isVisible: false,
                screenPath: Screens.PROFILE,
                screenName: <ProfileScreen />,
                title: Strings.PROFILE.TITLE_MENU,
                resourceCode: Constants.ResourceMenu.DASHBOARD,
            },

            // Invitation
            {
                key: "invitation",
                isVisible: true,
                icon: <Mail />,
                iconName: "send",
                screenName: <InvitationScreen />,
                screenPath: Screens.MANAGER_INVITATION,
                title: Strings.INVITATION.TITLE_MENU,
                resourceCode: Constants.ResourceMenu.INVITATION,
            },

            // Quản lý phân quyền (Authorization Management)
            {
                key: "role",
                isVisible: true,
                icon: <LockPerson />,
                iconName: "lock_person",
                screenName: <RoleScreen />,
                screenPath: Screens.ROLE_LIST,
                title: Strings.ROLE.TITLE_MENU,
                resourceCode: Constants.ResourceMenu.ROLE,
            },
            {
                key: "role_edit",
                isVisible: false,
                screenPath: Screens.ROLE_EDIT,
                screenName: <RoleScreen />,
                title: Strings.ROLE.TITLE_UPDATE_VIEW,
                resourceCode: Constants.ResourceMenu.ROLE,
            },
            {
                key: "role_create",
                isVisible: false,
                screenName: <RoleScreen />,
                screenPath: Screens.ROLE_CREATE,
                title: Strings.ROLE.TITLE_CREATE_VIEW,
                resourceCode: Constants.ResourceMenu.ROLE,
            },

            // Quản lý tổ chức (Organization Management)
            {
                isVisible: true,
                icon: <AddHomeWork />,
                iconName: "add_home_work",
                key: "organization-management",
                title: Strings.ORGANIZATION.TITLE_MENU_PARENT,
                resourceCode: [
                    Constants.ResourceMenu.ORGANIZATION,
                    Constants.ResourceMenu.GROUP,
                    Constants.ResourceMenu.STAFF,
                ],
                subMenu: [
                    // ORGANIZATION
                    {
                        key: "organization",
                        isVisible: true,
                        icon: <AssuredWorkload />,
                        iconName: "assured_workload",
                        screenName: <OrganizationScreen />,
                        screenPath: Screens.ORGANIZATION_LIST,
                        title: Strings.ORGANIZATION.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.ORGANIZATION,
                    },
                    {
                        key: "organization_edit",
                        isVisible: false,
                        screenPath: Screens.ORGANIZATION_EDIT,
                        screenName: <OrganizationScreen />,
                        title: Strings.ORGANIZATION.TITLE_UPDATE_VIEW,
                        resourceCode: Constants.ResourceMenu.ORGANIZATION,
                    },
                    {
                        key: "organization_create",
                        isVisible: false,
                        screenName: <OrganizationScreen />,
                        screenPath: Screens.ORGANIZATION_CREATE,
                        title: Strings.ORGANIZATION.TITLE_CREATE_VIEW,
                        resourceCode: Constants.ResourceMenu.ORGANIZATION,
                    },

                    // GROUP
                    {
                        key: "group",
                        isVisible: true,
                        icon: <Diversity1 />,
                        iconName: "diversity_1",
                        screenName: <GroupScreen />,
                        screenPath: Screens.GROUP_LIST,
                        title: Strings.GROUP.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.GROUP,
                    },
                    {
                        key: "group_edit",
                        isVisible: false,
                        screenPath: Screens.GROUP_EDIT,
                        screenName: <GroupScreen />,
                        title: Strings.GROUP.TITLE_UPDATE_VIEW,
                        resourceCode: Constants.ResourceMenu.GROUP,
                    },
                    {
                        key: "group_create",
                        isVisible: false,
                        screenName: <GroupScreen />,
                        screenPath: Screens.GROUP_CREATE,
                        title: Strings.GROUP.TITLE_CREATE_VIEW,
                        resourceCode: Constants.ResourceMenu.GROUP,
                    },

                    // STAFF
                    {
                        key: "staff",
                        isVisible: true,
                        icon: <Groups />,
                        iconName: "groups",
                        screenName: <StaffScreen />,
                        screenPath: Screens.STAFF_LIST,
                        title: Strings.STAFF.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.STAFF,
                    },
                    {
                        key: "staff_edit",
                        isVisible: false,
                        screenName: <StaffScreen />,
                        screenPath: Screens.STAFF_EDIT,
                        title: Strings.STAFF.TITLE_UPDATE_VIEW,
                        resourceCode: Constants.ResourceMenu.STAFF,
                    },
                    {
                        key: "staff_create",
                        isVisible: false,
                        screenName: <StaffScreen />,
                        screenPath: Screens.STAFF_CREATE,
                        title: Strings.STAFF.TITLE_CREATE_VIEW,
                        resourceCode: Constants.ResourceMenu.STAFF,
                    },
                ]
            },

            // Quản lý dịch vụ (Service)
            {
                isVisible: true,
                icon: <Category />,
                iconName: "category",
                key: "service-management",
                title: Strings.SERVICE.TITLE_MENU_PARENT,
                resourceCode: [
                    Constants.ResourceMenu.MANAGE_SERVICE,
                    Constants.ResourceMenu.MANAGE_CLIENT,
                    Constants.ResourceMenu.MANAGE_TENANT,
                ],
                subMenu: [
                    // Dịch vụ (Service)
                    {
                        key: "service",
                        isVisible: true,
                        screenName: <ServiceScreen />,
                        screenPath: Screens.SERVICE_LIST,
                        title: Strings.SERVICE.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.MANAGE_SERVICE,
                    },
                    {
                        key: "service_create",
                        isVisible: false,
                        screenName: <ServiceEditScreen />,
                        screenPath: Screens.SERVICE_CREATE,
                        title: Strings.SERVICE.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.MANAGE_SERVICE,
                    },
                    {
                        key: "service_edit",
                        isVisible: false,
                        screenName: <ServiceEditScreen />,
                        screenPath: Screens.SERVICE_EDIT,
                        title: Strings.SERVICE.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.MANAGE_SERVICE,
                    },

                    // Ứng dụng khách (Client)
                    {
                        key: "client",
                        isVisible: true,
                        screenPath: Screens.CLIENT_LIST,
                        screenName: <ClientScreen />,
                        title: Strings.CLIENT.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.MANAGE_CLIENT,
                    },
                    {
                        key: "client_create",
                        isVisible: false,
                        screenName: <ClientEditScreen />,
                        screenPath: Screens.CLIENT_CREATE,
                        title: Strings.CLIENT.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.MANAGE_CLIENT,
                    },
                    {
                        key: "client_edit",
                        isVisible: false,
                        screenName: <ClientEditScreen />,
                        screenPath: Screens.CLIENT_EDIT,
                        title: Strings.CLIENT.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.MANAGE_CLIENT,
                    },

                    // Người thuê (Tenant) 
                    {
                        key: "tenant",
                        isVisible: true,
                        screenName: <TenantScreen />,
                        screenPath: Screens.TENANT_LIST,
                        title: Strings.TENANT.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.MANAGE_TENANT,
                    },
                    {
                        key: "tenant_create",
                        isVisible: false,
                        screenName: <TenantEditScreen />,
                        screenPath: Screens.TENANT_CREATE,
                        title: Strings.TENANT.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.MANAGE_TENANT,
                    },
                    {
                        key: "tenant_edit",
                        isVisible: false,
                        screenName: <TenantEditScreen />,
                        screenPath: Screens.TENANT_EDIT,
                        title: Strings.TENANT.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.MANAGE_TENANT,
                    },
                ]
            },

            // Quản lý sự kiện
            {
                isVisible: true,
                key: "notification",
                screenName: <NotificationScreen />,
                screenPath: Screens.NOTIFICATION_LIST,
                title: Strings.NOTIFICATION.TITLE_MENU,
                resourceCode: Constants.ResourceMenu.NOTIFICATION,
            },
            {
                isVisible: false,
                key: "notification_detail",
                screenName: <NotificationScreen />,
                screenPath: Screens.NOTIFICATION_EDIT,
                title: Strings.NOTIFICATION.TITLE_DETAIL_VIEW,
                resourceCode: Constants.ResourceMenu.NOTIFICATION,
            },

            // Quản lý lịch sử đăng nhập của user
            {
                key: "loginlog",
                isVisible: true,
                icon: <ExitToApp />,
                iconName: "exit_to_app",
                screenPath: Screens.LOGIN_LOG,
                screenName: <LoginLogScreen />,
                title: Strings.LOGIN_LOG.TITLE_MENU,
                resourceCode: Constants.ResourceMenu.LOGIN_LOG,
            },

            // Quản lý sự kiện
            {
                key: "event",
                isVisible: true,
                screenName: <EventScreen />,
                title: Strings.EVENT.TITLE_MENU,
                screenPath: Screens.MANAGER_EVENT,
                resourceCode: Constants.ResourceMenu.EVENT,
            },
            {
                isVisible: false,
                key: "event_detail",
                screenName: <EventDetailScreen />,
                title: Strings.EVENT.TITLE_DETAIL_VIEW,
                screenPath: Screens.MANAGER_EVENT_DETAIL,
                resourceCode: Constants.ResourceMenu.EVENT,
            },

            // Cấu hình (Setting)
            {
                key: "setting",
                isVisible: true,
                icon: <Settings />,
                iconName: "settings",
                title: Strings.SETTING_COMMON.TITLE_MENU,
                resourceCode: [
                    Constants.ResourceMenu.MENU,
                    Constants.ResourceMenu.MODULE,
                    Constants.ResourceMenu.CODENAME,
                    Constants.ResourceMenu.SYSTEM_SETTING,
                ],
                subMenu: [
                    // Danh mục dùng chung (Common Category)
                    {
                        key: "codename",
                        isVisible: true,
                        screenName: <CodenameScreen />,
                        screenPath: Screens.CODENAME_LIST,
                        title: Strings.CODE_NAME.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.CODENAME,
                    },
                    {
                        key: "codename_create",
                        isVisible: false,
                        screenName: <CodenameScreen />,
                        screenPath: Screens.CODENAME_CREATE,
                        title: Strings.CODE_NAME.TITLE_CREATE_VIEW,
                        resourceCode: Constants.ResourceMenu.CODENAME,
                    },
                    {
                        key: "codename_edit",
                        isVisible: false,
                        screenPath: Screens.CODENAME_EDIT,
                        screenName: <CodenameScreen />,
                        title: Strings.CODE_NAME.TITLE_UPDATE_VIEW,
                        resourceCode: Constants.ResourceMenu.CODENAME,
                    },

                    // MENU
                    {
                        key: "menu",
                        isVisible: true,
                        screenName: <MenuScreen />,
                        screenPath: Screens.MENU_LIST,
                        title: Strings.MENU.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.MENU,
                    },
                    {
                        key: "menu_create",
                        isVisible: false,
                        screenName: <MenuEditScreen />,
                        screenPath: Screens.MENU_CREATE,
                        title: Strings.MENU.TITLE_CREATE_VIEW,
                        resourceCode: Constants.ResourceMenu.MENU,
                    },
                    {
                        key: "menu_edit",
                        isVisible: false,
                        screenPath: Screens.MENU_EDIT,
                        screenName: <MenuEditScreen />,
                        title: Strings.MENU.TITLE_UPDATE_VIEW,
                        resourceCode: Constants.ResourceMenu.MENU,
                    },

                    // MODULE
                    {
                        key: "module",
                        isVisible: true,
                        screenName: <ModuleScreen />,
                        screenPath: Screens.MODULE_LIST,
                        title: Strings.MODULE.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.MODULE,
                    },
                    {
                        key: "module_create",
                        isVisible: false,
                        screenName: <ModuleScreen />,
                        screenPath: Screens.MODULE_CREATE,
                        title: Strings.MODULE.TITLE_CREATE_VIEW,
                        resourceCode: Constants.ResourceMenu.MODULE,
                    },
                    {
                        key: "module_edit",
                        isVisible: false,
                        screenPath: Screens.MODULE_EDIT,
                        screenName: <ModuleScreen />,
                        title: Strings.MODULE.TITLE_UPDATE_VIEW,
                        resourceCode: Constants.ResourceMenu.MODULE,
                    },

                    // RESOURCE
                    {
                        key: "resource",
                        isVisible: true,
                        screenName: <ResourceScreen />,
                        screenPath: Screens.RESOURCE_LIST,
                        title: Strings.RESOURCE.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.RESOURCE,
                    },
                    {
                        key: "resource_edit",
                        isVisible: false,
                        screenPath: Screens.RESOURCE_EDIT,
                        screenName: <ResourceEditScreen />,
                        title: Strings.RESOURCE.TITLE_UPDATE_VIEW,
                        resourceCode: Constants.ResourceMenu.RESOURCE,
                    },

                    //Cấu hình hệ thống (System Setting)  
                    {
                        key: "system",
                        isVisible: true,
                        screenName: <SystemSettingScreen />,
                        screenPath: Screens.SYSTEM_SETTING_LIST,
                        title: Strings.SYSTEM_SETTING.TITLE_MENU,
                        resourceCode: Constants.ResourceMenu.SYSTEM_SETTING,
                    },
                    {
                        isVisible: false,
                        key: "system_edit",
                        screenName: <SystemSettingEditScreen />,
                        screenPath: Screens.SYSTEM_SETTING_EDIT,
                        title: Strings.SYSTEM_SETTING.TITLE_DETAIL_VIEW,
                        resourceCode: Constants.ResourceMenu.SYSTEM_SETTING,
                    },
                ]
            },

        ];

        setRoutes(mainRoutes);
    }, [key]);

    return routes;
}

