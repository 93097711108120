import Constants from "constants/index";
import IdentityService from "./indentity.service";

class UserService extends IdentityService {

    public getPaged = async (data: any) => {
        const result = await this.api.get({
            path: `${Constants.ApiPath.USER.GET_PAGED}?${data}`,
        });
        return result.data.result;
    }

}

export default UserService;