import Helpers from "commons/helpers";
import Constants from "constants/index";
import ProfileService from "services/identity/profile.service";

import { OrganizationType } from "constants/enum";
import { call, put, takeLatest } from "redux-saga/effects";
import { IUserProfile, RoleLevel, RoleType } from "@maysoft/common-component-react";
import { ICodename, IOrganizationUserProfile, IUserAuthorization, IUserInfo } from "commons/interfaces";
import { UserInfoState, fetchUserInfo, fetchUserInfoFailed, fetchUserInfoSuccess } from "store/slice/userInfo.slice";

const profileService = new ProfileService();

const ROLE_DEFAULT = RoleType.Normal | RoleType.Member;

function* fetchingUser() {
    try {
        // const oldState: UserInfoState = yield select((state) => state.userInfo);
        // if (oldState.userProfile) {
        //     yield put(fetchUserInfoSuccess(undefined));
        //     return;
        // }

        let organizationId = Helpers.getItemInLocalStorage(Constants.StorageKeys.ORGANIZATION_ID, "") as string;

        const resultProfile: IUserInfo = yield call(profileService.getUserInfo);

        let organizationType = OrganizationType.Normal;
        const currentServiceOrganizations =
            [...(resultProfile?.currentServiceOrganizations || [])].filter((el) => el.tenantCode === Constants.TENANT_CODE.IDENTITY) || [];

        if (currentServiceOrganizations.length > 0) {
            const itemFirst = currentServiceOrganizations?.[0];
            const index = currentServiceOrganizations?.findIndex((el) => el.id === organizationId);
            if (index === -1 && organizationId !== "0") {
                organizationId = itemFirst?.id;
                organizationType = itemFirst?.type;
            }
        } else {
            organizationId = "0";
        }

        const userAuthorization: IUserAuthorization = yield call(
            profileService.getUserAuthorization,
            organizationId === "0" ? undefined : organizationId
        );

        let itemOrganizationUserProfile: IOrganizationUserProfile | undefined = [...(resultProfile.organizationUserProfiles || [])]?.find(
            (item) => item.organizationId === organizationId
        );

        let itemUserAuthorizationResponse = {
            roleCode: "",
            roleType: ROLE_DEFAULT,
            roleLevel: RoleLevel.Default,
            roleName: "Organization Member",
        } as any;

        if ([...(userAuthorization?.userAuthorizationResponse || [])].length > 0) {
            itemUserAuthorizationResponse = [...(userAuthorization?.userAuthorizationResponse || [])].reduce((prev, curr) => {
                return prev.roleLevel < curr.roleLevel ? prev : curr;
            }, [...(userAuthorization?.userAuthorizationResponse || [])][0]);
        }

        let propsTemp: any = {
            email: resultProfile?.userProfile?.email,
            gender: resultProfile?.userProfile?.gender,
            birthDate: resultProfile?.userProfile?.birthDate,
            phoneNumber: resultProfile?.userProfile?.phoneNumber,
            fullName: resultProfile?.userProfile?.fullName || resultProfile?.userProfile?.email,
            status: resultProfile?.userProfile?.status || 0,
        };

        if (itemOrganizationUserProfile) {
            const fullName = `${itemOrganizationUserProfile?.lastName || ""} ${itemOrganizationUserProfile?.firstName || ""}`.trim();

            propsTemp = {
                gender: itemOrganizationUserProfile?.gender,
                email: itemOrganizationUserProfile?.email || "",
                fullName: fullName || itemOrganizationUserProfile?.email,
                birthDate: itemOrganizationUserProfile?.dateOfBirth || 0,
                phoneNumber: itemOrganizationUserProfile?.phoneNumber || "",

                status: itemOrganizationUserProfile?.activeStatus || 0,
            };
        }

        const userProfile: IUserProfile = {
            id: resultProfile?.userProfile?.id || itemOrganizationUserProfile?.id || "",
            organizationId: organizationId === "0" ? "" : organizationId,
            groupId: "",

            currency: "VND",
            organizationType: organizationType,

            roleCode: itemUserAuthorizationResponse?.roleCode || "",
            roleName: itemUserAuthorizationResponse?.roleName || "",
            roleType: itemUserAuthorizationResponse?.roleType ?? ROLE_DEFAULT,
            roleLevel: itemUserAuthorizationResponse?.roleLevel ?? RoleLevel.Default,

            userName: resultProfile?.userProfile?.userName || "",
            avatarId: resultProfile?.userProfile?.avatarId || "",
            avatarUrl: resultProfile?.userProfile?.avatarUrl || "",
            identityId: resultProfile?.userProfile?.identityId || "",

            ...propsTemp,
        };

        const listOrganization: ICodename[] = currentServiceOrganizations?.map((e) => {
            return {
                code: e.id,
                name: Helpers.renderValueByLanguage(e.name?.value) || "",
                detail: {
                    gene: e.gene,
                    organizationCode: e.organizationCode,
                },
            };
        });

        const listGroup: ICodename[] = [...(resultProfile.groupUsers || [])]?.map((item) => {
            return {
                code: item.groupId,
                name: Helpers.renderValueByLanguage(item.groupName?.value) || "",
                detail: {
                    gene: item.groupGene,
                    group: item.organizationId,
                },
            };
        });

        const resourceMenu = [...(userAuthorization?.menus || [])];
        const menuDetails = [...(userAuthorization?.menuDetails || [])];

        const resourceCodes = [...(userAuthorization?.roleResourcePermissions || [])]?.map((item) => ({
            resourceURI: item.resourceURI,
            permission: item.permission,
        }));

        const result: UserInfoState = {
            userProfile,

            listGroup,
            listOrganization,
            currentOrganization: organizationId || "",

            menuDetails,
            resourceMenu,
            resourceCodes,
        };

        Helpers.setItemInLocalStorage(Constants.StorageKeys.ORGANIZATION_ID, organizationId || "");

        yield put(fetchUserInfoSuccess(result));
    } catch (error) {
        yield put(fetchUserInfoFailed());
    }
}

export default function* userInfoSaga() {
    yield takeLatest(fetchUserInfo().type, fetchingUser);
}
