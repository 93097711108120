import { Card, Grid, Icon } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Helpers from "commons/helpers";
import Constants from "constants/index";
import Screens from "constants/screens";
import Strings from "constants/strings";
import MenuService from "services/identity/menu.service";
import MaterialIcons from "assets/data/material-icons-type";
import AutocompleteIconMUI from "components/AutocompleteIconMUI";

import { RootState } from "store";
import { DashboardLayout } from "layout";
import { commonGetAllDataClient } from "screens/client";
import { setDataAlert } from "store/slice/message.slice";
import { commonGetAllDataService } from "screens/service";
import { showLoading } from "store/slice/loadingAPI.slice";
import { commonGetAllDataResource } from "screens/resource";
import { IsTrue, Mode, ResourceType } from "constants/enum";
import { ICodename, IRecordMenuDetail, IModel } from "commons/interfaces";
import {
    Typography, ControlLabelCheckBox,
    Box, Autocomplete, ImageUploader,
    FormField, SelectMultiLanguage,
    DataTable, Modal, Button,
    useCommonComponentContext,
} from "@maysoft/common-component-react";



interface IIDataError {
    title?: string,
    clientId?: string
    isExternal?: string,
    externalUrl?: string,
    serviceCode?: string,
}

interface IExtraInformation {
    gene?: string;
    screenPath?: string;
    screenName?: string;
    resourceURI?: string;
    id?: string | number;
}

const menuService = new MenuService();

const MenuEditScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getResourcePermissions } = useCommonComponentContext();

    const [language, setLanguage] = useState<string>("");
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [model, setModel] = useState<IModel>({} as IModel);
    const [dataError, setDataError] = useState<IIDataError>({} as IIDataError);
    const [dataDetail, setDataDetail] = useState<IRecordMenuDetail>({} as IRecordMenuDetail);

    const [dataICONS, setDataICONS] = useState<ICodename[]>([]);
    const [dataClient, setDataClient] = useState<ICodename[]>([]);
    const [dataServices, setDataServices] = useState<ICodename[]>([]);
    const [parentMenuList, setParentMenuList] = useState<ICodename[]>([]);
    const [targetResourceList, setTargetResourceList] = useState<ICodename[]>([]);

    const [dataRowExtraInfor, setDataRowExtraInfor] = useState<IExtraInformation[]>([]);
    const [dataModalExtraInfor, setDataModalExtraInfor] = useState<{
        open: boolean,
        data?: IExtraInformation,
        error?: IExtraInformation,
    }>({ open: false });

    const resourcePermissions = getResourcePermissions(Constants.ResourceCode.MENU);
    const listPathName = useSelector((state: RootState) => state.titleRoute.listPathName);

    const idDetail = searchParams.get("id");
    const pramsMode = !Helpers.isNullOrEmpty(searchParams.get("mode")) ? Number(searchParams.get("mode")) : undefined;

    const valueLogoId = useMemo(() => {
        if (Helpers.isNullOrEmpty(dataDetail?.iconUrl)) {
            return undefined;
        } else {
            const temps = `${dataDetail?.iconUrl}`.split("/");
            return temps[temps?.length - 1];
        }
    }, [dataDetail?.iconUrl]);

    const listMultiLanguage = useMemo(() => {
        return [
            { code: Constants.LanguageContent.VI, name: Strings.Languages.VI },
            { code: Constants.LanguageContent.EN, name: Strings.Languages.EN },
        ]
    }, [Strings.getLanguage()]);

    useEffect(() => { getData(); }, [idDetail]);

    const handleGoBack = () => {
        const itemPathName = listPathName.find(el => el.pathName === Screens.MENU_LIST);
        navigate(Screens.MENU_LIST + (itemPathName ? itemPathName?.query : ""));
    }

    const handleChangeMode = (value: Mode) => {
        let title: string = "";
        let route = [{ title: Strings.MENU.TITLE_MENU, route: Screens.MENU_LIST }];

        if (value === Mode.Create) {
            title = Strings.MENU.TITLE_CREATE_VIEW;
            route = [
                { title: Strings.MENU.TITLE_MENU, route: Screens.MENU_LIST },
                { title: Strings.Common.CREATE_NEW, route: "" }
            ];
        }
        if (value === Mode.Update) {
            title = Strings.MENU.TITLE_UPDATE_VIEW;
            route = [
                { title: Strings.MENU.TITLE_MENU, route: Screens.MENU_LIST },
                { title: Strings.Common.UPDATE, route: "" }
            ];
        }
        if (value === Mode.View) {
            title = Strings.MENU.TITLE_DETAIL_VIEW;
            route = [
                { title: Strings.MENU.TITLE_MENU, route: Screens.MENU_LIST },
                { title: Strings.Common.VIEW, route: "" }
            ];
        }

        setModel({
            ...model,
            mode: value,
            title: title,
            route: route,
        });

        if (!Helpers.isNullOrEmpty(idDetail)) {
            navigate(Screens.MENU_EDIT + `?id=${idDetail}&mode=${value}`, { replace: true });
        }
    }

    const getData = async () => {
        try {
            dispatch(showLoading(true));

            const resultDataService = await commonGetAllDataService();
            setDataServices(resultDataService);

            let newData: IRecordMenuDetail = {
                tenantCode: Constants.TENANT_CODE.IDENTITY,
                clientId: searchParams.get("clientId") || Constants.CLIENT_ID,
                serviceCode: searchParams.get("serviceCode") || Constants.SERVICE_CODE.IDENTITY,
            };

            if (!Helpers.isNullOrEmpty(idDetail)) {
                const result = await menuService.getDetail(idDetail);

                let mode = resourcePermissions.canUpdate ? (pramsMode || Mode.Update) : Mode.View;

                newData = {
                    ...newData,
                    clientId: result.clientId,
                    tenantCode: result.tenantCode,
                    serviceCode: result.serviceCode,

                    id: idDetail,
                    icon: result.icon,
                    title: result.title,
                    iconUrl: result.iconUrl,
                    screenName: result.screenName,
                    isExternal: result.isExternal,
                    externalUrl: result.externalUrl,
                    resourceURI: result.resourceURI,
                    displayOrder: result.displayOrder,
                    extraInformation: result.extraInformation,
                    parentMenu: (result.parentMenu === 0 || result.parentMenu === "0") ? "" : result.parentMenu,
                    targetResource: (result.targetResource === 0 || result.targetResource === "0") ? "" : result.targetResource,
                };

                if (!Helpers.isNullOrEmpty(result.extraInformation)) {
                    const newDataRow: IExtraInformation[] = [];
                    const extraInformation = JSON.parse(result.extraInformation);

                    if (Array.isArray(extraInformation)) {
                        extraInformation.forEach((item, index) => {
                            newDataRow.push({
                                id: index,
                                gene: item.Gene || item.gene,
                                screenName: item.ScreenName || item.screenName,
                                screenPath: item.ScreenPath || item.screenPath,
                                resourceURI: item.ResourceURI || item.resourceURI,
                            });
                        });
                    } else {
                        newDataRow.push({
                            id: Date.now(),
                            gene: extraInformation?.Gene || extraInformation?.gene,
                            screenName: extraInformation?.ScreenName || extraInformation?.screenName,
                            screenPath: extraInformation?.ScreenPath || extraInformation?.screenPath,
                            resourceURI: extraInformation?.ResourceURI || extraInformation?.resourceURI,
                        });
                    }

                    setDataRowExtraInfor(newDataRow);
                }

                handleChangeMode(mode);
            } else {
                handleChangeMode(Mode.Create);
            }

            await getAllClient(newData?.serviceCode || Constants.SERVICE_CODE.IDENTITY);
            await getTargetResourcelist(newData?.serviceCode || Constants.SERVICE_CODE.IDENTITY);
            await getParentMenuList(newData?.serviceCode || Constants.SERVICE_CODE.IDENTITY, newData?.clientId || Constants.CLIENT_ID);

            const iconMui = MaterialIcons.listName.map(item => { return { code: item, name: item } });
            setDataICONS(iconMui);

            const newlanguage = Strings.getLanguage();
            setLanguage(newlanguage);

            setDataError({});
            setIsEdited(false);
            setDataDetail(newData);

            dispatch(showLoading(false));
        } catch (error) {
            dispatch(showLoading(false));
            const e = Helpers.renderExceptionError(error);
            dispatch(setDataAlert({ message: e, type: "error" }));
        }
    }

    const getParentMenuList = async (serviceCode: string, clientId: string) => {
        try {
            dispatch(showLoading(true));

            let listTemp: ICodename[] = [];
            let data = {
                serviceCode: serviceCode,
                clientId: clientId,
            }

            const result = await menuService.getAll(data);
            result.forEach((e: any) => {
                listTemp.push({
                    code: e.id,
                    name: Helpers.renderValueByLanguage(e.title?.value)
                })
            });
            setParentMenuList(listTemp);

            dispatch(showLoading(false));
        } catch (error) {
            setParentMenuList([]);
            dispatch(showLoading(false));
        }
    }

    const getAllClient = async (serviceCode: string) => {
        let listTemp: ICodename[] = await commonGetAllDataClient({ serviceCode })
        setDataClient(listTemp);
    }

    const getTargetResourcelist = async (serviceCode: string) => {
        let listTemp: ICodename[] = await commonGetAllDataResource({
            isCode: false,
            serviceCode: serviceCode,
            resourceType: ResourceType.Module,
        });
        setTargetResourceList(listTemp);
    }

    const onValueChange = async (value: any, key: string) => {
        if (key === "serviceCode") {
            dispatch(showLoading(true));

            await getAllClient(value);
            await getTargetResourcelist(value);
            await getParentMenuList(value, dataDetail?.clientId);

            dispatch(showLoading(false));
        }
        if (key === "clientId") {
            await getParentMenuList(dataDetail?.serviceCode, value);
        }

        setIsEdited(true);

        setDataDetail((prev) => {
            let newData: any = { ...prev };
            switch (key) {
                case "title":
                    newData[key] = {
                        ...newData[key],
                        value: {
                            ...newData[key]?.value,
                            [language]: value,
                        }
                    };
                    break;
                case "isExternal":
                    newData["isExternal"] = value;
                    newData["externalUrl"] = value ? newData["externalUrl"]?.value : "";
                    break;
                case "serviceCode":
                    newData["serviceCode"] = value;
                    newData["clientId"] = "";
                    newData["parentMenu"] = "";
                    newData["targetResource"] = "";
                    break;
                case "clientId":
                    newData["clientId"] = value;
                    newData["parentMenu"] = "";
                    break;
                default:
                    newData[key] = value;
                    break;
            };
            return newData;
        });

        setDataError((prev) => {
            let newData: any = { ...prev };
            switch (key) {
                case "isExternal":
                    newData["isExternal"] = undefined;
                    newData["externalUrl"] = undefined;
                    break;
                case "clientId":
                    newData["clientId"] = undefined;
                    newData[key] = undefined;
                    break;
                default:
                    newData[key] = undefined;
                    break;
            };
            return newData;
        });
    }

    const checkedValidated = () => {
        let checked = true;
        const newError: any = { ...dataError };

        if (Helpers.isNullOrEmpty((dataDetail as any)?.["clientId"])) {
            newError["clientId"] = Strings.Common.FIELD_REQUIRED;
            checked = false;
        }

        if (Helpers.isNullOrEmpty((dataDetail as any)?.["title"]?.value?.[language])) {
            newError["title"] = Strings.Common.FIELD_REQUIRED;
            checked = false;
        }

        if (dataDetail?.isExternal) {
            if (Helpers.isNullOrEmpty((dataDetail as any)["externalUrl"])) {
                newError["externalUrl"] = Strings.Common.FIELD_REQUIRED;
                checked = false;
            }
        };

        if (!checked) {
            dispatch(setDataAlert({ message: Strings.Message.PLEASE_COMPLETE_ALL_INFORMATION, type: "error" }));
            setDataError(newError);
        }

        return checked
    }

    const onSubmit = async () => {
        if (model.mode === Mode.View) {
            handleChangeMode(Mode.Update);
        } else {
            const checked = checkedValidated();
            if (!checked) {
                return false;
            } else {
                try {
                    dispatch(showLoading(true));

                    const newExtra = dataRowExtraInfor?.map(el => ({
                        "Gene": el.gene,
                        "ScreenPath": el.screenPath,
                        "ScreenName": el.screenName,
                        "ResourceURI": el.resourceURI,
                    }));

                    const extraInformation: any = (dataRowExtraInfor?.length === 0) ? undefined : JSON.stringify(newExtra);

                    let data: any = {
                        clientId: dataDetail?.clientId,
                        serviceCode: dataDetail?.serviceCode,

                        icon: dataDetail?.icon || undefined,
                        iconUrl: dataDetail?.iconUrl || undefined,
                        parentMenu: dataDetail?.parentMenu || undefined,
                        screenName: dataDetail?.screenName || undefined,
                        externalUrl: dataDetail?.externalUrl || undefined,
                        resourceURI: dataDetail?.resourceURI || undefined,
                        isExternal: dataDetail?.isExternal || IsTrue.False,
                        displayOrder: dataDetail?.displayOrder || undefined,
                        targetResource: dataDetail?.targetResource || undefined,
                        title: Helpers.setValueMultiLanguage(dataDetail.title, language, listMultiLanguage) || { value: { [language]: "" } },
                        extraInformation: extraInformation,
                    };

                    let result: any;
                    if (Helpers.isNullOrEmpty(dataDetail?.id)) {
                        result = await menuService.create(data);
                    } else {
                        data = { ...data, id: dataDetail?.id }
                        result = await menuService.update(data);
                    }

                    if (result.statusCode === Constants.ApiCode.SUCCESS) {
                        dispatch(setDataAlert({
                            message: Helpers.isNullOrEmpty(dataDetail?.id)
                                ? Strings.Message.CREATE_SUCCESS
                                : Strings.Message.UPDATE_SUCCESS,
                            type: "success"
                        }));
                    }

                    if (Helpers.isNullOrEmpty(dataDetail?.id)) {
                        handleGoBack();
                    } else {
                        setIsEdited(false);
                        handleChangeMode(Mode.View);
                    }

                    dispatch(showLoading(false));
                } catch (error) {
                    dispatch(showLoading(false));
                    const e = Helpers.renderExceptionError(error);
                    dispatch(setDataAlert({ message: e, type: "error" }));
                }
            }
        }
    }

    // Extra-Infor 
    const handleOnActionExtraInfor = () => {
        let checked = true;
        if (Helpers.isNullOrEmpty(dataModalExtraInfor?.data?.screenName)) {
            checked = false;
            setDataModalExtraInfor(prev => ({
                ...prev,
                error: {
                    ...prev.error,
                    screenName: Strings.Validation.REQUIRED,
                },
            }));
        }
        if (Helpers.isNullOrEmpty(dataModalExtraInfor?.data?.screenPath)) {
            checked = false;
            setDataModalExtraInfor(prev => ({
                ...prev,
                error: {
                    ...prev.error,
                    screenPath: Strings.Validation.REQUIRED,
                },
            }));
        }

        if (checked) {
            let newRows = [...dataRowExtraInfor || []];
            if (Helpers.isNullOrEmpty(dataModalExtraInfor?.data?.id)) {
                newRows.push({
                    ...dataModalExtraInfor?.data,
                    id: Date.now(),
                });
            } else {
                const index = newRows.findIndex(el => el.id === dataModalExtraInfor?.data?.id);
                if (index !== -1) {
                    newRows[index] = { ...dataModalExtraInfor?.data };
                }
            }

            setDataRowExtraInfor(newRows);
            setDataModalExtraInfor({
                data: {},
                error: {},
                open: false,
            });
        }
    }

    const handleDeleteExtraInfor = (id: string | number) => {
        let newRows = [...dataRowExtraInfor || []];

        newRows = newRows.filter(el => el.id !== id);

        setDataRowExtraInfor(newRows);
    }
    // 

    const renderLogoMenu = () => (
        <Card>
            <Box p={2} textAlign="center">
                <ImageUploader
                    // width={120}
                    // height={120}
                    imageRatio="1:1"
                    fileId={valueLogoId}
                    disabled={model.mode === Mode.View}
                    onChangeImage={(data) => {
                        if (Helpers.isNullOrEmpty(data?.accessUrl)
                            && Helpers.isNullOrEmpty(data?.id)) {
                            return;
                        } else {
                            onValueChange(data?.accessUrl, "iconUrl")
                        }
                    }}
                />
                <Typography variant="h6">{Strings.MENU.ADD_ICON_IMG}</Typography>
            </Box>
        </Card>
    );

    const renderBasicInfo = () => (
        <Card>
            <Box p={2}>
                <Box
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ display: "flex", flexWrap: "wrap" }}
                >
                    <Typography variant="h5">{Strings.MENU.BASIC_INFO}</Typography>
                    {
                        (model?.mode !== Mode.View) &&
                        <SelectMultiLanguage
                            defaultLanguage={language}
                            onChangeLanguage={(value: string) => {
                                const title = Helpers.setValueMultiLanguage(dataDetail.title, value, listMultiLanguage) || { value: { [value]: "" } }

                                setLanguage(value);
                                setDataDetail(prev => ({
                                    ...prev,
                                    title: title,
                                }));
                            }}
                        />
                    }
                </Box>
                <Grid container spacing={3} p={2}>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            required
                            isSelectedBox
                            mode={model.mode}
                            data={dataServices || []}
                            label={Strings.SERVICE.NAME}
                            errorMessage={dataError?.serviceCode}
                            defaultValue={dataDetail?.serviceCode}
                            disabled={!Helpers.isNullOrEmpty(dataDetail?.id)}
                            onChange={(value) => onValueChange(value, "serviceCode")}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            required
                            mode={model.mode}
                            data={dataClient || []}
                            label={Strings.SERVICE.CLIENT}
                            errorMessage={dataError?.clientId}
                            defaultValue={dataDetail?.clientId}
                            disabled={!Helpers.isNullOrEmpty(dataDetail?.id)}
                            onChange={(value) => onValueChange(value, "clientId")}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormField
                            required
                            maxLength={255}
                            mode={model.mode}
                            label={Strings.MENU.MENU_TITLE}
                            errorMessage={dataError?.title}
                            placeholder={Strings.MENU.ENTER_TITLE_MENU}
                            value={dataDetail?.title?.value?.[language] || ""}
                            onChangeValue={(value) => onValueChange(value, "title")}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            mode={model.mode}
                            label={Strings.MENU.PARENT_MENU}
                            defaultValue={dataDetail?.parentMenu}
                            placeholder={Strings.MENU.SELECT_PARENT_MENU}
                            onChange={(value) => onValueChange(value, "parentMenu")}
                            data={[...parentMenuList || []].filter(el => el.code !== dataDetail?.id)}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormField
                            isMoney
                            type="number"
                            maxLength={20}
                            mode={model.mode}
                            value={dataDetail?.displayOrder}
                            label={Strings.MENU.DISPLAY_ORDER}
                            placeholder={Strings.MENU.ENTER_DISPLAY_ORDER}
                            onChangeValue={(value) => {
                                const newval = Number(value) < 0 ? 0 : value;
                                onValueChange(newval, "displayOrder");
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {(model.mode === Mode.View)
                            ? (
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={6} lg={6} xl={4}>
                                        <Typography variant="button" fontWeight="bold">{Strings.MENU.ICON_NAME}:</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6} xl={8}>
                                        {dataDetail?.icon &&
                                            <Icon style={{ fontSize: "40px" }}>{dataDetail?.icon || ""}</Icon>
                                        }
                                    </Grid>
                                </Grid>
                            )
                            :
                            <AutocompleteIconMUI
                                data={dataICONS || []}
                                label={Strings.MENU.ICON_NAME}
                                defaultValue={dataDetail?.icon || ""}
                                placeholder={Strings.MENU.ENTER_ICON_NAME}
                                onChange={(value) => onValueChange(value, "icon")}
                            />
                        }
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormField
                            maxLength={255}
                            mode={model.mode}
                            label={Strings.MENU.RESOURCE_URI}
                            value={dataDetail?.resourceURI || ""}
                            placeholder={Strings.MENU.ENTER_RESOURCE_URI}
                            onChangeValue={(value) => onValueChange(value, "resourceURI")}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            isGroupBy
                            mode={model.mode}
                            data={targetResourceList || []}
                            label={Strings.MENU.GROUP_MODULE}
                            defaultValue={dataDetail?.targetResource}
                            placeholder={Strings.MENU.SELECT_GROUP_MODULE}
                            onChange={(value) => onValueChange(value, "targetResource")}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormField
                            maxLength={255}
                            mode={model.mode}
                            label={Strings.MENU.SCREEN_NAME}
                            value={dataDetail?.screenName || ""}
                            isFullRow={model.mode !== Mode.View}
                            placeholder={Strings.MENU.ENTER_SCREEN_NAME}
                            onChangeValue={(value) => onValueChange(value, "screenName")}
                        />
                    </Grid>

                    <Grid item xs={12} md={(model.mode === Mode.View) ? 6 : 12}>
                        {(model.mode !== Mode.View) &&
                            <ControlLabelCheckBox
                                label={Strings.MENU.IS_EXTERNAL}
                                disabled={model.mode === Mode.View}
                                value={dataDetail?.isExternal === IsTrue.True}
                                onChangeValue={(value) => {
                                    onValueChange(value ? IsTrue.True : IsTrue.False, "isExternal")
                                }}
                            />
                        }
                        {
                            ((model.mode === Mode.View) || (dataDetail?.isExternal === IsTrue.True)) &&
                            <FormField
                                maxLength={255}
                                mode={model.mode}
                                sx={{ marginTop: 2 }}
                                label={Strings.MENU.EXTERNAL_URL}
                                errorMessage={dataError?.externalUrl}
                                value={dataDetail?.externalUrl || ""}
                                placeholder={Strings.MENU.ENTER_EXTERNAL_URL}
                                required={(dataDetail?.isExternal === IsTrue.True)}
                                onChangeValue={(value) => onValueChange(value, "externalUrl")}
                            />
                        }
                    </Grid>

                    {/* <Grid item xs={12} md={12}>
                        <FormField
                            multiline
                            isFullRow
                            maxLength={500}
                            mode={model.mode}
                            label={Strings.MENU.EXTRA_INFORMATION}
                            value={dataDetail?.extraInformation || ""}
                            placeholder={Strings.MENU.ENTER_EXTRA_INFORMATION}
                            onChangeValue={(value) => onValueChange(value, "extraInformation")}
                        />
                    </Grid> */}
                </Grid>
            </Box>
        </Card>
    );

    const renderExtraInfor = () => (
        <Card>
            <Box p={2}>
                <Box sx={{
                    marginBottom: 2,
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                }} >
                    <Typography variant="h5">{Strings.MENU.EXTRA_INFORMATION}</Typography>
                    {model.mode !== Mode.View &&
                        <Button onClick={() => {
                            setDataModalExtraInfor({
                                open: true,
                                error: {},
                                data: {},
                            });
                        }}>
                            {Strings.Common.ADD_NEW}
                        </Button>
                    }
                </Box>

                <DataTable
                    isLocal
                    isShowIndex
                    loading={false}
                    mode={model.mode}

                    pageNumber={1}
                    rowPerPage={5}
                    rowPerPageOptions={[5, 10, 20]}
                    totalCount={dataRowExtraInfor.length}

                    table={{
                        rows: dataRowExtraInfor || [],
                        columns: [
                            { Header: Strings.MENU.EXTERNAL_URL, accessor: "screenPath", },
                            { Header: Strings.MENU.SCREEN_NAME, accessor: "screenName", },
                            { Header: Strings.MENU.RESOURCE_URI, accessor: "resourceURI", },
                        ],
                    }}

                    actionList={row => [
                        {
                            key: "edit",
                            iconName: "edit",
                            title: Strings.Common.EDIT,
                            callback: (row) => {
                                setDataModalExtraInfor({
                                    open: true,
                                    error: {},
                                    data: { ...row },
                                });
                            },
                        },
                        {
                            key: "delete",
                            iconName: "delete",
                            title: Strings.Common.DELETE,
                            callback: (row) => {
                                handleDeleteExtraInfor(row?.id);
                            },
                        },
                    ]}
                />
            </Box>
        </Card>
    );

    const renderModalExtraInfor = () => (
        <Modal
            fullWidth
            hasActionButton
            visible={dataModalExtraInfor?.open}
            title={dataModalExtraInfor?.data?.id
                ? Strings.Common.EDIT
                : Strings.Common.ADD_NEW
            }
            onAction={() => { handleOnActionExtraInfor() }}
            onClose={() => {
                setDataModalExtraInfor({
                    open: false,
                    error: {},
                    data: {},
                });
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormField
                        required
                        maxLength={255}
                        mode={model.mode}
                        label={Strings.MENU.EXTERNAL_URL}
                        placeholder={Strings.MENU.ENTER_EXTERNAL_URL}
                        value={dataModalExtraInfor?.data?.screenPath || ""}
                        errorMessage={dataModalExtraInfor?.error?.screenPath}
                        onChangeValue={(value) => {
                            setDataModalExtraInfor(prev => ({
                                ...prev,
                                data: {
                                    ...prev.data,
                                    screenPath: value,
                                },
                                error: {
                                    ...prev.error,
                                    screenPath: undefined,
                                },
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormField
                        required
                        maxLength={255}
                        mode={model.mode}
                        label={Strings.MENU.SCREEN_NAME}
                        placeholder={Strings.MENU.ENTER_SCREEN_NAME}
                        value={dataModalExtraInfor?.data?.screenName || ""}
                        errorMessage={dataModalExtraInfor?.error?.screenName}
                        onChangeValue={(value) => {
                            setDataModalExtraInfor(prev => ({
                                ...prev,
                                data: {
                                    ...prev.data,
                                    screenName: value,
                                },
                                error: {
                                    ...prev.error,
                                    screenName: undefined,
                                },
                            }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormField
                        maxLength={255}
                        mode={model.mode}
                        label={Strings.MENU.RESOURCE_URI}
                        placeholder={Strings.MENU.ENTER_RESOURCE_URI}
                        value={dataModalExtraInfor?.data?.resourceURI || ""}
                        errorMessage={dataModalExtraInfor?.error?.resourceURI}
                        onChangeValue={(value) => {
                            setDataModalExtraInfor(prev => ({
                                ...prev,
                                data: {
                                    ...prev.data,
                                    resourceURI: value,
                                },
                                error: {
                                    ...prev.error,
                                    resourceURI: undefined,
                                },
                            }));
                        }}
                    />
                </Grid>
            </Grid>
        </Modal>
    );

    return (
        <DashboardLayout
            title={model.title}
            route={model.route}
            isPermission={resourcePermissions.canRead}
            nameActionPress={(model.mode === Mode.View) ? Strings.Common.EDIT : Strings.Common.SAVE}
            onActionPress={(resourcePermissions.canCreate || resourcePermissions.canUpdate) ? onSubmit : undefined}
            onBackPress={() => {
                if (isEdited) {
                    Helpers.showConfirmAlert(Strings.Message.CANCEL_ACTION_MESSAGE, handleGoBack);
                } else {
                    handleGoBack()
                }
            }}
        >
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={3} >
                        {renderLogoMenu()}
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                        {renderBasicInfo()}
                        <Box marginTop={3}>
                            {renderExtraInfor()}
                        </Box>
                    </Grid>
                </Grid>

                {renderModalExtraInfor()}
            </Box>
        </DashboardLayout>
    )
}

export default MenuEditScreen;